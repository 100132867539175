import React, { Component } from "react";
import { connect } from "react-redux";

import { CONSTANTS } from "utilities/constants";
import { CONFIGURATION } from "utilities/configuration";
import { OutlinedInput } from "@mui/material";

import { LocalizeByLang } from "utilities/localize";

import "./profileInformation.css";
import commonUtilities from "utilities/common";

class ProfileInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areaCode: "",
      lead: "",
      lead4: "",
      ext: "",
      languageCode: this.props.languageCode ?? commonUtilities.getLanguage()
    };
  }

  componentDidMount() {
    const isHomePage = this.props.isHomePage ?? false;
    const { groupInformation } = isHomePage ? this.props.quote : this.props;
    const agentPhone = groupInformation?.AgentPhone;
    const ext = groupInformation?.AgentPhoneExt;

    const areaCode = agentPhone?.slice(0, 3);
    const lead = agentPhone?.slice(3, 6);
    const lead4 = agentPhone?.slice(6, 10);

    this.setState({ areaCode, lead, lead4, ext });
  }

  localize = (code) => {
    return LocalizeByLang(code, this.state.languageCode);
  }

  handlePhoneareaCode = (event) => {
    const areaCode = event.target.value.replace(/\D/g, "");
    let lead = this.state.lead;
    let lead4 = this.state.lead4;
    const data = {
      target: {
        value:  `${areaCode}${lead}${lead4}`,
        name: "AgentPhone"
      }
    };
    this.setState({ areaCode });
    this.props.handleStep1Data(data);
  };

  handlePhonelead = (event) => {
    const lead = event.target.value.replace(/\D/g, "");
    let areaCode = this.state.areaCode;
    let lead4 = this.state.lead4;
    const data = {
      target: {
        value:  `${areaCode}${lead}${lead4}`,
        name: "AgentPhone"
      }
    };
    this.setState({ lead });
    this.props.handleStep1Data(data);
  };

  handlePhonelead4 = (event) => {
    const lead4 = event.target.value.replace(/\D/g, "");
    let areaCode = this.state.areaCode;
    let lead = this.state.lead;
    const data = {
      target: {
        value:  `${areaCode}${lead}${lead4}`,
        name: "AgentPhone"
      }
    };
    this.setState({ lead4 });
    this.props.handleStep1Data(data);
  };

  handlePhoneExt = (event) => {
    const ext = event.target.value.replace(/\D/g, "");
    const data = {
      target: {
        value: `${ext}`,
        name: "AgentPhoneExt"
      }
    };
    this.setState({ ext });
    this.props.handleStep1Data(data);
  };

  render() {
    const { profile, sfdc, group } = this.props;
    const forPDF = this.props.forPDF ?? false;
    const isHomePage = this.props.isHomePage ?? false;

    let AlterNumber = group?.agentPhone;
    let extension = group?.agentPhoneExt;
    const data = profile?.data;

    let isIQMUser = this.props.isIQMUser;
    if(isIQMUser === undefined) {
      isIQMUser = commonUtilities.isIQMUser();
    }

    if (data) {
      let {
        contactType,
        company,
        customerType,
        agencyNumber,
        agencyName,
        firstName,
        lastName,
        phones,
        emailAddress
      } = data;
      let phone =
        phones && phones.length ? phones[0] : CONSTANTS.DEFAULT_STRING_VALUE;
      let { phoneNumber } = phone;
      let { email } = emailAddress || "";

      if (sfdc?.data) {
        let groupInfo = sfdc?.data?.data;
        agencyNumber = groupInfo?.agencyNumber;
        company = groupInfo?.company;
        customerType = groupInfo?.customerType;
        firstName = groupInfo?.firstName;
        lastName = groupInfo?.lastName;
        phoneNumber = groupInfo?.phone;
        email = groupInfo?.email;
        phone = groupInfo?.phone;
        extension = groupInfo?.extension;
      }

      return (
        <div className="profile-information-container">
          {isIQMUser ? (
            <div>
              <div className="row font-bold margin-bottom">
                {customerType?.toUpperCase() === CONFIGURATION.CONTACT_TYPE ||
                customerType?.toUpperCase() === CONFIGURATION.CUSTOMER_TYPE
                  ? this.localize("qma-agency-information-header")
                  : this.localize("qma-customer-information-header")}
              </div>

              {(customerType?.toUpperCase() === CONFIGURATION.CONTACT_TYPE ||
                customerType?.toUpperCase() === CONFIGURATION.CUSTOMER_TYPE) &&
              company !== null ? (
                <div className="row">
                  <div className="heading">
                    {customerType?.toUpperCase() ===
                      CONFIGURATION.CONTACT_TYPE ||
                    customerType?.toUpperCase() === CONFIGURATION.CUSTOMER_TYPE
                      ? this.localize("qma-agency")
                      : this.localize("qma-customer")}
                  </div>
                  <div className="value">{company}</div>
                </div>
              ) : null}

              <div className="row">
                <div className="heading">
                  {customerType?.toUpperCase() === CONFIGURATION.CONTACT_TYPE ||
                  customerType?.toUpperCase() === CONFIGURATION.CUSTOMER_TYPE
                    ? this.localize("qma-agent")
                    : this.localize("qma-customer")}
                </div>
                <div className="value">{firstName + " " + lastName}</div>
              </div>

              <div className="row">
                <div className="heading">{this.localize("qma-phoneNumber")}</div>
                <div className="value">
                  {customerType?.toUpperCase() === CONFIGURATION.CONTACT_TYPE ||
                  customerType?.toUpperCase() ===
                    CONFIGURATION.CUSTOMER_TYPE ? (
                    agencyNumber !== undefined &&
                    agencyNumber !== null &&
                    agencyNumber.trim().length > 0 ? (
                      agencyNumber
                    ) : (
                      <div className="not-available">
                        {this.localize("qma-not-available")}
                      </div>
                    )
                  ) : phoneNumber !== undefined &&
                    phoneNumber !== null &&
                    phoneNumber.trim().length > 0 ? (
                    phoneNumber
                  ) : (
                    <div className="not-available">
                      {this.localize("qma-not-available")}
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="heading">{this.localize("qma-email")}</div>
                <div className="value">
                  {email !== undefined ? (
                    <a
                      className="link-button"
                      href={"mailTo:" + email}
                      target="_blank"
                    >
                      {email}
                    </a>
                  ) : (
                    CONSTANTS.DEFAULT_STRING_VALUE
                  )}
                </div>
              </div>
              <div className="row">
                <div className="heading">
                  {phone !== null && phone !== undefined && phone > 0 ? this.localize("qma-alternatephoneNumber") : null}
                </div>
                <div className="value">
                  {phone !== null && phone !== undefined && phone > 0
                    ? "(" +
                      phone.slice(0, 3) +
                      ") " +
                      phone.slice(3, 6) +
                      "-" +
                      phone.slice(6, 10)
                    : null}
                  {extension !== null && extension
                    ? " " + this.localize("qma-extphoneNumber") + " " + extension
                    : null}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="row font-bold margin-bottom">
                {contactType?.toUpperCase() === CONFIGURATION.CONTACT_TYPE
                  ? this.localize("qma-agency-information-header")
                  : this.localize("qma-customer-information-header")}
              </div>

              {contactType?.toUpperCase() === CONFIGURATION.CONTACT_TYPE ? (
                <div className="row">
                  <div className="heading">
                    {contactType?.toUpperCase() === CONFIGURATION.CONTACT_TYPE
                      ? this.localize("qma-agency")
                      : this.localize("qma-customer")}
                  </div>
                  <div className="value">{agencyName}</div>
                </div>
              ) : null}

              <div className="row">
                <div className="heading">
                  {contactType?.toUpperCase() === CONFIGURATION.CONTACT_TYPE
                    ? this.localize("qma-agent")
                    : this.localize("qma-customer")}
                </div>
                <div className="value">{firstName + " " + lastName}</div>
              </div>

              <div className="row">
                <div className="heading">{this.localize("qma-phoneNumber")}</div>
                <div className="value">
                  {agencyNumber ? (
                    agencyNumber !== undefined &&
                    agencyNumber !== null &&
                    agencyNumber.trim().length > 0 ? (
                      agencyNumber
                    ) : (
                      <div className="not-available">
                        {this.localize("qma-not-available")}
                      </div>
                    )
                  ) : phoneNumber !== undefined &&
                    phoneNumber !== null &&
                    phoneNumber.trim().length > 0 ? (
                    phoneNumber
                  ) : (
                    <div className="not-available">
                      {this.localize("qma-not-available")}
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="heading">{this.localize("qma-email")}</div>
                <div className="value">
                  {email !== undefined ? (
                    <a
                      className="link-button"
                      href={"mailTo:" + email}
                      target="_blank"
                    >
                      {email}
                    </a>
                  ) : (
                    CONSTANTS.DEFAULT_STRING_VALUE
                  )}
                </div>
              </div>              
              <div className="row">
                <div className="heading">
                  {(contactType?.toUpperCase() === CONFIGURATION.CONTACT_TYPE ||
                  customerType?.toUpperCase() === CONFIGURATION.CUSTOMER_TYPE) &&
                  (isHomePage || AlterNumber && AlterNumber !== undefined && AlterNumber.trim().length > 0)
                    ? this.localize("qma-alternatephoneNumber")
                    : null}
                </div>
                {
                  (contactType?.toUpperCase() === CONFIGURATION.CONTACT_TYPE ||
                  customerType?.toUpperCase() === CONFIGURATION.CUSTOMER_TYPE) &&
                  AlterNumber && AlterNumber !== undefined && AlterNumber.trim().length > 0 ? (
                  <div>
                    {"(" +
                      AlterNumber.slice(0, 3) +
                      ") " +
                      AlterNumber.slice(3, 6) +
                      "-" +
                      AlterNumber.slice(6, 10)}
                    {extension &&
                    extension !== undefined &&
                    extension.trim().length
                      ? " " + this.localize("qma-extphoneNumber") + " " + extension
                      : null}
                  </div>
                ): null
                }
                {(contactType?.toUpperCase() === CONFIGURATION.CONTACT_TYPE ||
                  customerType?.toUpperCase() === CONFIGURATION.CUSTOMER_TYPE) &&
                  (isHomePage) && !forPDF? (
                <div className="value">
                  (<OutlinedInput
                        className="column-min"
                        name="areaCode"
                        type="text"
                        id="outlined-alternat-areacode"
                        inputProps={{ maxLength: 3 }}
                        onChange={this.handlePhoneareaCode}
                        value={this.state.areaCode}
                      />)
                  <OutlinedInput
                        className="column-min"
                        name="lead"
                        type="text"
                        id="outlined-alternat-lead"
                        inputProps={{ maxLength: 3 }}
                        onChange={this.handlePhonelead}
                        value={this.state.lead}
                      />-
                  <OutlinedInput
                        className="column-min"
                        name="lead4"
                        type="text"
                        id="outlined-alternat-lead4"
                        inputProps={{ maxLength: 4 }}
                        onChange={this.handlePhonelead4}
                        value={this.state.lead4}
                      />
                  {this.localize("qma-extphoneNumber")}
                  <OutlinedInput
                        className="column-min"
                        name="ext"
                        type="text"
                        id="outlined-ext"
                        inputProps={{ maxLength: 4 }}
                        value={this.state.ext}
                        onChange={this.handlePhoneExt}
                      />
                </div>):null
                }
              </div>
            </div>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  sfdc: state.sfdc
});

export default connect(mapStateToProps)(ProfileInformation);
