import React, { Component } from "react";
import { Icon } from "__ICON_REFERENCE__";
import { Tooltip } from "@mui/material";

import Moment from "moment";
import { Localize } from "utilities/localize";
import commonUtilities from "utilities/common";
import { withRouter } from "utilities/withRouter";
import { CONFIGURATION } from "utilities/configuration";

import "./productType.css";

class ProductType extends Component {
  render() {
    const { quote, handleItinerary } = this.props;

    if (quote) {
      const { quoteOptions } = quote;
      const date = new Date();
      date.setHours(0,0,0,0);
      const TODAY = date.toDateString();
  
      return (
        <div className="row product-type-container">
          {quoteOptions?.map((quoteOption, index) => (
            <div key={index} className="row margin-top-2x border-top">
              <div className="row margin-top-2x">
                <strong>
                  {"Option " +
                    (index + 1) +
                    " - " +
                    quoteOption?.productType?.name}
                </strong>
              </div>

              {quoteOption?.productType?.code?.toLowerCase() !== "deviation" ? (
                <div>
                  {quoteOption?.products?.map((product, productIndex) => (
                    <div
                      key={productIndex}
                      className="row margin-top-1x margin-bottom-1x"
                    >
                      <div className="hotel-pic">
                        {product?.url?.trim().length > 0 ? (
                          <img src={product?.url} alt="text" />
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="hotel-details">
                        <div className="row">
                          <div className="pull-left option-icon">
                            {quoteOption?.productType?.code
                              ?.trim()
                              .toLowerCase() === "flight" ? (
                              <Icon
                                name="map"
                                className={
                                  "icon-16 margin-right font-icon flight-icon"
                                }
                              />
                            ) : (
                              <Icon
                                name={
                                  quoteOption?.productType?.code
                                    ?.trim()
                                    .toLowerCase() === "package"
                                    ? "hotel"
                                    : quoteOption?.productType?.code
                                        ?.trim()
                                        .toLowerCase()
                                }
                                className={
                                  "icon-16 margin-right font-icon flight-icon"
                                }
                              />
                            )}
                          </div>
                          <div className="value">
                            <strong>
                              {quoteOption?.productType?.code
                                ?.trim()
                                .toLowerCase() === "flight" ? (
                                <div className="text-ellipsis">
                                  {quoteOption?.destination?.name +
                                    " (" +
                                    quoteOption.destination.providerCode +
                                    ")"}{" "}
                                </div>
                              ) : (
                                <div className="text-ellipsis">
                                  {product.name}
                                </div>
                              )}
                            </strong>
                          </div>
                        </div>

                        {quoteOption?.productType?.code.trim().toLowerCase() ===
                          "package" ||
                        quoteOption?.productType?.code.trim().toLowerCase() ===
                          "flight" ? (
                          <div className="row margin-top">
                            <div className="pull-left option-icon">
                              <Icon
                                name="flight"
                                className="icon-16 font-icon margin-right"
                              />
                            </div>

                            <div className="text-ellipsis">
                              {quoteOption.departure.code !== ""
                                ? "(" + quoteOption.departure.code + ") "
                                : ""}
                              {quoteOption.departure.name !== ""
                                ? quoteOption.departure.name + " - "
                                : ""}
                              {quoteOption.destination.providerCode !== ""
                                ? "(" +
                                  quoteOption.destination.providerCode +
                                  ") "
                                : ""}
                              {quoteOption.destination.name !== ""
                                ? quoteOption.destination.name
                                : ""}
                            </div>
                          </div>
                        ) : null}

                        {quoteOption?.productType?.code.trim().toLowerCase() ===
                        "cruise" ? (
                          <div className="row margin-top">
                            <div className="pull-left option-icon">
                              <Icon
                                name="map"
                                className="icon-16 font-icon margin-right"
                              />
                            </div>

                            <div className="pull-left">{product.location}</div>
                          </div>
                        ) : null}

                        <div className="row margin-top">
                          <div className="pull-left option-icon">
                            <Icon
                              name="calendar"
                              className="icon-16 font-icon margin-right"
                            />
                          </div>
                          <div className="pull-left">
                            {Moment(quoteOption.departureDate).format(
                              CONFIGURATION.DATE_FORMAT
                            ) +
                              " - " +
                              Moment(quoteOption.returnDate).format(
                                CONFIGURATION.DATE_FORMAT
                              ) +
                              " | " +
                              quoteOption?.duration}
                            {" " + Localize("qma-night")}
                          </div>
                        </div>

                        <div className="row margin-top">
                          <div className="pull-left option-icon">
                            <Icon
                              name="passengers"
                              className="icon-16 font-icon margin-right"
                            />
                          </div>
                          <div className="pull-left">
                            {quoteOption.noOfAdults > 0 &&
                              `${quoteOption.noOfAdults} ${Localize(
                                quoteOption.noOfAdults > 1
                                  ? "qma-adults"
                                  : "qma-adult"
                              )}`}
                            {quoteOption.noOfChildren > 0 &&
                              `, ${quoteOption.noOfChildren} ${Localize(
                                quoteOption.noOfChildren > 1
                                  ? "qma-children"
                                  : "qma-child"
                              )}`}
                          </div>
                        </div>

                        <div className="row margin-top">
                          <div className="pull-left option-icon">
                            <Icon
                              name="calendar"
                              className="icon-16 font-icon margin-right"
                            />
                          </div>
                          <div className="pull-left">
                            {" " + Localize("qma-quote-created-on") + " "}
                            {Moment(quoteOption.optionCreatedDate).format(
                              CONFIGURATION.DATE_FORMAT
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="itinerary-details">
                        <div className="row">
                          <div className="itinerary-col text-ellipsis">
                            <strong>{Localize("qma-itinerary")}</strong>
                          </div>
                          <div className="itinerary-validity-col text-ellipsis">
                            <strong>{Localize("qma-validity")}</strong>
                          </div>
                        </div>
                        {
                          product.itineraries?.map((itinerary, itineraryIndex) => 
                            itinerary?.status?.toLowerCase() !== "inactive" ? 
                            <div key={itineraryIndex} className="row">
                              <div className="itinerary-col text-ellipsis">
                                <Tooltip
                                    title={itinerary?.heading}
                                    placement="top-start"
                                >
                                  <a
                                      data-testid="itinerary-click"
                                      onClick={(event) =>
                                        handleItinerary(event, itinerary)
                                      }
                                      className="link-button"
                                    >
                                      {itinerary?.status !== "" ? (
                                        <div className="row">
                                          <div className="pull-left margin-right">
                                            {itinerary.itineraryNumber}
                                          </div>
                                          {itinerary?.status?.toLowerCase() ===
                                            "pending" ||
                                          itinerary?.status?.toLowerCase() ===
                                            "unavailable" ? (
                                            <div
                                              className={
                                                itinerary?.status?.toLowerCase() +
                                                " itinerary-status margin-right"
                                              }
                                            >
                                              {itinerary?.statusLocalized}
                                            </div>
                                          ) : null}
                                          <div className="pull-left">
                                            <Icon
                                              name={
                                                quoteOption?.productType?.code
                                                  .trim()
                                                  .toLowerCase() === "package"
                                                  ? "flight"
                                                  : quoteOption?.productType?.code
                                                      .trim()
                                                      .toLowerCase()
                                              }
                                              className={
                                                "icon-16 margin-right flight-icon"
                                              }
                                            />
                                          </div>
                                          <div className="itinerary-heading text-ellipsis">
                                            {itinerary.heading}
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="row">
                                          <div className="pull-left margin-right">
                                            {itinerary.itineraryNumber}
                                          </div>
                                          <div className="pull-left">
                                            <Icon
                                              name={
                                                quoteOption?.productType?.code
                                                  .trim()
                                                  .toLowerCase() === "package"
                                                  ? "flight"
                                                  : quoteOption?.productType?.code
                                                      .trim()
                                                      .toLowerCase()
                                              }
                                              className={
                                                "icon-16 margin-right flight-icon"
                                              }
                                            />
                                          </div>
                                          <div className="itinerary-heading text-ellipsis">
                                            {itinerary.heading}
                                          </div>
                                        </div>
                                      )}
                                    </a>
                                </Tooltip>
                              </div>
                              <div className="itinerary-validity-col text-ellipsis">
                                {itinerary.status === "Inactive" || itinerary.status === "Pending" ? null :
                                (itinerary.itineraryValidDate !== "" &&
                                  itinerary.itineraryValidDate !== null ? (
                                    commonUtilities.calculateDuration(TODAY, itinerary.itineraryValidDate) < 0 ? (
                                      <div className="validity-row">
                                        {Localize("qma-expired")}
                                      </div>
                                    ) : commonUtilities.calculateDuration(
                                      TODAY,
                                      itinerary.itineraryValidDate
                                    ) < 4 &&
                                    commonUtilities.calculateDuration(
                                      TODAY,
                                      itinerary.itineraryValidDate
                                    ) >= 0 ? (
                                      <div className="validity-row font-error">
                                        <Icon
                                          name="alert--o"
                                          className="icon-12 font-error pull-left margin-right"
                                        />
                                        {Localize("qma-expires-on") + " "}
                                        {Moment(
                                          itinerary.itineraryValidDate
                                        ).format(CONFIGURATION.DATE_FORMAT)}
                                      </div>
                                    ) : (
                                      <div className="validity-row">
                                        <Icon
                                          name="calendar"
                                          className="icon-12 font-icon pull-left margin-top-1-4 margin-right"
                                        />
                                        {Localize("qma-expires-on") + " "}
                                        {Moment(
                                          itinerary.itineraryValidDate
                                        ).format(CONFIGURATION.DATE_FORMAT)}
                                      </div>
                                    )
                                  ) : (
                                    <div className="validity-row">{Localize("qma-expired")}</div>
                                  ))
                                }
                              </div>
                            </div> : null
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  {quoteOption?.deviationItinerary !== null ? (
                    <div className="row margin-top-1x margin-bottom-1x">
                      <div className="hotel-pic">
                        {
                          <img
                            src={
                              quoteOption?.deviationItinerary
                                ?.deviationRequest[0]?.products[0]?.url
                            }
                            alt="text"
                          />
                        }
                      </div>
                      <div className="hotel-details">
                        <div className="row">
                          <div className="pull-left option-icon">
                            {
                              <Icon
                                name="map"
                                className={
                                  "icon-16 margin-right font-icon flight-icon"
                                }
                              />
                            }
                          </div>
                          <div className="value">
                            <strong>
                              {quoteOption.destination.name !== ""
                                ? quoteOption.destination.name + " "
                                : ""}
                              {quoteOption.destination.providerCode !== ""
                                ? "(" +
                                  quoteOption.destination.providerCode +
                                  ") "
                                : ""}
                            </strong>
                          </div>
                        </div>

                        {
                          <div className="row margin-top">
                            <div className="pull-left option-icon">
                              <Icon
                                name={quoteOption?.deviationItinerary?.deviationRequest[0]?.products[0]?.productType?.toLowerCase()}
                                className={
                                  "icon-16 margin-right font-icon flight-icon"
                                }
                              />
                            </div>

                            <div className="text-ellipsis">
                              <strong>
                                {
                                  <div className="text-ellipsis">
                                    {
                                      quoteOption?.deviationItinerary
                                        ?.deviationRequest[0]?.products[0]?.name
                                    }
                                  </div>
                                }
                              </strong>
                            </div>
                          </div>
                        }

                        <div className="row margin-top">
                          <div className="pull-left option-icon">
                            <Icon
                              name="passengers"
                              className="icon-16 font-icon margin-right"
                            />
                          </div>
                          <div className="pull-left">
                            {quoteOption.noOfAdults > 0 &&
                              `${quoteOption.noOfAdults} ${Localize(
                                quoteOption.noOfAdults > 1
                                  ? "qma-adults"
                                  : "qma-adult"
                              )}`}
                            {quoteOption.noOfChildren > 0 &&
                              `, ${quoteOption.noOfChildren} ${Localize(
                                quoteOption.noOfChildren > 1
                                  ? "qma-children"
                                  : "qma-child"
                              )}`}
                          </div>
                        </div>


                        <div className="row margin-top">
                          <div className="pull-left option-icon">
                            <Icon
                              name="calendar"
                              className="icon-16 font-icon margin-right"
                            />
                          </div>
                          <div className="pull-left">
                            {" " + Localize("qma-quote-created-on") + " "}
                            {Moment(quoteOption.optionCreatedDate).format(
                              CONFIGURATION.DATE_FORMAT
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="itinerary-details">
                        <div className="itinerary-col text-ellipsis">
                          <div className="row">
                            <strong>{Localize("qma-itinerary")}</strong>
                          </div>
                          {
                            <div className="row">
                              <a
                                data-testid="itinerary-click"
                                onClick={(event) =>
                                  handleItinerary(
                                    event,
                                    quoteOption?.deviationItinerary
                                  )
                                }
                                className="link-button"
                              >
                                {quoteOption?.status !== "" ? (
                                  <div className="row">
                                    {quoteOption?.deviationItinerary?.status?.toLowerCase() ===
                                    "inactive" ? (
                                      ""
                                    ) : (
                                      <div>
                                        <div className="pull-left margin-right">
                                          {
                                            quoteOption?.deviationItinerary
                                              ?.itineraryNumber
                                          }
                                        </div>

                                        {quoteOption?.deviationItinerary?.status?.toLowerCase() ===
                                          "pending" ||
                                        quoteOption?.deviationItinerary?.status?.toLowerCase() ===
                                          "unavailable" ? (
                                          <div
                                            className={
                                              quoteOption?.deviationItinerary?.status?.toLowerCase() +
                                              " itinerary-status margin-right"
                                            }
                                          >
                                            {
                                              quoteOption?.deviationItinerary
                                                ?.status
                                            }
                                          </div>
                                        ) : null}
                                        <div className="pull-left">
                                          <Icon
                                            name={
                                              quoteOption?.productType?.code
                                                .trim()
                                                .toLowerCase() === "package"
                                                ? "flight"
                                                : quoteOption?.productType?.code
                                                    .trim()
                                                    .toLowerCase()
                                            }
                                            className={
                                              "icon-16 margin-right flight-icon"
                                            }
                                          />
                                        </div>
                                        <div className="itinerary-heading text-ellipsis">
                                          {
                                            quoteOption?.deviationItinerary
                                              ?.heading
                                          }
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    {quoteOption?.deviationItinerary?.status?.toLowerCase() ===
                                    "inactive" ? (
                                      ""
                                    ) : (
                                      <div className="row">
                                        <div className="pull-left margin-right">
                                          {
                                            quoteOption?.deviationItinerary
                                              ?.itineraryNumber
                                          }
                                        </div>
                                        <div className="pull-left">
                                          <Icon
                                            name={
                                              quoteOption?.productType?.code
                                                .trim()
                                                .toLowerCase() === "package"
                                                ? "flight"
                                                : quoteOption?.productType?.code
                                                    .trim()
                                                    .toLowerCase()
                                            }
                                            className={
                                              "icon-16 margin-right flight-icon"
                                            }
                                          />
                                        </div>
                                        <div className="itinerary-heading text-ellipsis">
                                          {
                                            quoteOption?.deviationItinerary
                                              ?.heading
                                          }
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </a>
                            </div>
                          }
                        </div>

                        <div className="itinerary-validity-col text-ellipsis">
                          <div className="row">
                            <strong>{Localize("qma-validity")}</strong>
                          </div>

                          {
                            <div className="row">
                              { (quoteOption?.deviationItinerary?.status === "Pending" || quoteOption?.deviationItinerary?.status === "Inactive") ? null :
                                (quoteOption?.deviationItinerary?.itineraryValidDate !== "" &&
                                  quoteOption?.deviationItinerary?.itineraryValidDate !== null ? (
                                  commonUtilities.calculateDuration(
                                    TODAY,
                                    quoteOption?.deviationItinerary
                                      ?.itineraryValidDate
                                  ) < 0 ? (
                                    <div className="validity-row">
                                      {Localize("qma-expired")}
                                    </div>
                                  ) : commonUtilities.calculateDuration(
                                      TODAY,
                                      quoteOption?.deviationItinerary
                                        ?.itineraryValidDate
                                    ) < 4 &&
                                    commonUtilities.calculateDuration(
                                      TODAY,
                                      quoteOption?.deviationItinerary
                                        ?.itineraryValidDate
                                    ) >= 0 ? (
                                    <div className="validity-row font-error">
                                      <Icon
                                        name="alert--o"
                                        className="icon-12 font-error pull-left margin-right"
                                      />
                                      {Localize("qma-expires-on") + " "}
                                      {Moment(
                                        quoteOption?.deviationItinerary
                                          ?.itineraryValidDate
                                      ).format(CONFIGURATION.DATE_FORMAT)}
                                    </div>
                                  ) : (
                                    <div className="validity-row">
                                      <Icon
                                        name="calendar"
                                        className="icon-12 font-icon pull-left margin-top-1-4 margin-right"
                                      />
                                      {Localize("qma-expires-on") + " "}
                                      {Moment(
                                        quoteOption?.deviationItinerary
                                          ?.itineraryValidDate
                                      ).format(CONFIGURATION.DATE_FORMAT)}
                                    </div>
                                  )
                                ) : (
                                  <div className="validity-row">&nbsp;</div>
                                ))}
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div></div>
                </div>
              )}
            </div>
          ))}
        </div>
      );
    }
  }
}

export default withRouter(ProductType);
