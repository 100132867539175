import React, { Component } from "react";

import { LocalizeByLang } from "utilities/localize";
import { withRouter } from "utilities/withRouter";
import commonUtilities from "utilities/common";
import "./groupDetails.css";

class GroupDetails extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
          languageCode: this.props.languageCode ?? commonUtilities.getLanguage()
        };
      }

    localize = (code) => {
        return LocalizeByLang(code, this.state.languageCode);
    }

    render() {

        const { group } = this.props;
        const selectedLang = this.state.languageCode;

        const groupTypes = [
            {
              "eventKey": "Sports & Wellness",
              "eventTypeNameEn": "Sports & Wellness",
              "eventTypeNameFr": "Sports et bien-être"
            },
            {
              "eventKey": "Student",
              "eventTypeNameEn": "Student",
              "eventTypeNameFr": "Étudiant"
            },
            {
              "eventKey": "Leisure",
              "eventTypeNameEn": "Leisure",
              "eventTypeNameFr": "Loisir"
            },
            {
              "eventKey": "Wedding",
              "eventTypeNameEn": "Wedding",
              "eventTypeNameFr": "Mariage"
            },
            {
              "eventKey": "Corporate & Specialty",
              "eventTypeNameEn": "Corporate & Specialty",
              "eventTypeNameFr": "Enterprise et Spécialité"
            }
          ];


          const getEventTypeName = (key) => {
            let selectedGroup = "";
            return groupTypes?.map(group => {
                if(group?.eventKey === key){
                    return (selectedLang === "en") ? group?.eventTypeNameEn : group?.eventTypeNameFr;
                }
            });
          };


        if (group) {

            const { groupName, groupType, noOfTravellers  } = group;
            return (
                <div className="group-information-container">
                    <div className="row margin-bottom">
                        <strong>
                            {this.localize("qma-group-details")}
                        </strong>
                    </div>
                    <div className="row">
                        <div className="heading">
                            {this.localize("qma-group-name")}
                        </div>
                        <div className="value text-ellipsis">
                            {groupName}
                        </div>
                    </div>
                    <div className="row">
                        <div className="heading">
                            {this.localize("qma-group-type")}
                        </div>
                        <div className="value">
                            {getEventTypeName(groupType)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="heading">
                            {this.localize("qma-travellers")}
                        </div>
                        <div className="value">
                            {noOfTravellers}
                        </div>
                    </div>
                </div>
            );

        }

    }
}

export default GroupDetails;