import React, { Component } from "react";
import { Icon } from "__ICON_REFERENCE__";
import Moment from "moment";
import commonUtilities from "utilities/common";
import { LocalizeByLang } from "utilities/localize";
import { CONFIGURATION } from "utilities/configuration";
import ProductTypeIcon from "../../../common/productTypeIcon/productTypeIcon";
import ProductTypeItinerary from "../productTypeItinerary/productTypeItinerary";

import "./productTypeDetails.css";

class ProductTypeDetails extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          languageCode: this.props.languageCode ?? commonUtilities.getLanguage()
        };
      }

    localize = (code) => {
        return LocalizeByLang(code, this.state.languageCode);
    }
    
    render() {
        const { quote, handleItinerary } = this.props;
        const forPDF = this.props.forPDF ?? false;

        if (quote?.data) {
            const { quoteOptions } = quote?.data || [];
            const isItinerary = false;
            
            let options = [];
            quoteOptions.forEach(quoteOption => {
                let deviationProducts = commonUtilities.getDeviationProducts(quoteOption);
                options.push({... quoteOption, deviationProducts});
            })

            return (
                <div className="row view-quote-product-type productype-container">
                    {options?.map((quoteOption, index) => (
                        <div key={index} className="row margin-top">
                            <div className="is-mobile">
                                <div className="view-quote-product-type-row margin-top-2x">

                                    {
                                        (quoteOption?.productType?.code.trim().toLowerCase() === "hotel"
                                            || quoteOption?.productType?.code.trim().toLowerCase() === "cruise")
                                            ? <h3>
                                                {"Option " + (index + 1)}
                                            </h3>
                                            : <h3>
                                                {"Option " +
                                                    (index + 1) +
                                                    "- " + this.localize("qma-gateway") + " (" +
                                                    quoteOption.departure.code +
                                                    ") " +
                                                    quoteOption.departure.name}
                                            </h3>
                                    }

                                    <div className="pull-right">
                                        <h3>
                                            <ProductTypeIcon productType={quoteOption?.productType?.code.toLowerCase() === "deviation" ? "package" : quoteOption?.productType?.code.toLowerCase()} />
                                            {quoteOption?.productType?.name}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            {forPDF ? null : 
                                // is-desktop is for mobile class in css
                                <div className="is-desktop">
                                    <div className="view-quote-product-type-row margin-top-2x">

                                        {
                                            (quoteOption?.productType?.code.trim().toLowerCase() === "flight"
                                                || quoteOption?.productType?.code.trim().toLowerCase() === "hotel"
                                                || quoteOption?.productType?.code.trim().toLowerCase() === "cruise"
                                                || quoteOption?.productType?.code.trim().toLowerCase() === "deviation")
                                                ? <div className="col-option-text">
                                                    <h3>
                                                        {"Option " + (index + 1)}
                                                    </h3>
                                                </div>
                                                : <div className="col-option-text">
                                                    <h3>
                                                        {"Option " + (index + 1)}
                                                    </h3>
                                                </div>
                                        }

                                        <div className="col-option-gateway">
                                            <h3 className="row">
                                                <div className="pull-right">
                                                    <ProductTypeIcon productType={quoteOption?.productType?.code.toLowerCase()} />
                                                    {quoteOption?.productType?.name}
                                                </div>
                                            </h3>
                                            <h3 className={quoteOption?.productType?.code.toLowerCase() !== "hotel" ? "show   margin-right" : "hide"}>
                                                {" From (" + quoteOption.departure.code + ") " +
                                                    quoteOption.departure.name}
                                            </h3>

                                        </div>
                                    </div>
                                </div>
                            }

                            {quoteOption?.productType?.code?.toLowerCase() !== "deviation" ?
                                <div>
                                    {quoteOption.products?.map((product, productIndex) => (
                                        <div key={productIndex} className="row margin-top-1x product-row">
                                            <div className="hotel-pic">
                                                {product?.url?.trim()?.length > 0 ? (
                                                    <img src={product.url} />
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            <div className="hotel-details">
                                                <div className="row">
                                                    <div className="row">
                                                        {
                                                            quoteOption?.productType?.code.trim().toLowerCase() === "flight"
                                                                ? <strong>{quoteOption.destination.name + " (" + quoteOption.destination.providerCode + ")"}</strong>
                                                                : <strong>{product.name}</strong>
                                                        }
                                                    </div>
                                                    <div className="row">
                                                        {
                                                            (quoteOption?.productType?.code.trim().toLowerCase() === "flight")
                                                                ? null
                                                                : (quoteOption?.productType?.code.trim().toLowerCase() === "cruise")
                                                                    ? ""
                                                                    : <div> {product.city + ", " + product.country} </div>
                                                        }

                                                    </div>
                                                </div>

                                                {
                                                    quoteOption?.productType?.code.trim().toLowerCase() !== "flight"
                                                        ? <div className="row margin-top-2x d-flex">
                                                            <div className="pull-left option-icon">
                                                                <Icon
                                                                    name="map"
                                                                    className="icon-16 font-icon margin-right"
                                                                />
                                                            </div>
                                                            <div className="pull-left option-text">{product.location}</div>
                                                        </div>
                                                        : <div className="row margin-top-2x">
                                                            <div className="pull-left option-icon">
                                                                <Icon
                                                                    name="flight"
                                                                    className="icon-16 font-icon margin-right"
                                                                />
                                                            </div>
                                                            <div className="pull-left option-text">
                                                                {"(" + quoteOption.departure.code + ") " + quoteOption.departure.name + " - (" + quoteOption.destination.providerCode + ") " + quoteOption.destination.name}
                                                            </div>
                                                        </div>
                                                }

                                                <div className="row margin-top-1-4 d-flex">
                                                    <div className="pull-left option-icon">
                                                        <Icon
                                                            name="calendar"
                                                            className="icon-16 font-icon margin-right"
                                                        />
                                                    </div>
                                                    <div className="pull-left option-text">
                                                        {Moment(quoteOption.departureDate).format(CONFIGURATION.DATE_FORMAT) +
                                                            " - " +
                                                            Moment(quoteOption.returnDate).format(CONFIGURATION.DATE_FORMAT) +
                                                            " | " +
                                                            quoteOption?.duration}
                                                        {" " + this.localize("qma-night")}
                                                    </div>
                                                </div>

                                                <div className="row margin-top-1-4">
                                                    <div className="pull-left option-icon">
                                                        <Icon
                                                            name="passengers"
                                                            className="icon-16 font-icon margin-right"
                                                        />
                                                    </div>
                                                    <div className="pull-left option-text">
                                                        {quoteOption.noOfAdults > 0 && `${quoteOption.noOfAdults} ${this.localize(quoteOption.noOfAdults > 1 ? "qma-adults" : "qma-adult")}`}
                                                        {quoteOption.noOfChildren > 0 && `, ${quoteOption.noOfChildren} ${this.localize(quoteOption.noOfChildren > 1 ? "qma-children" : "qma-child")}`}
                                                    </div>
                                                </div>
                                            </div>

                                            <ProductTypeItinerary
                                                handleItinerary={(event, data) =>
                                                    handleItinerary(event, data, isItinerary)
                                                }
                                                quote={quote}
                                                quoteOption={quoteOption}
                                                product={product}
                                                productType={quoteOption?.productType?.name.toLowerCase()}
                                                forPDF={forPDF}
                                                languageCode={this.state.languageCode}
                                            />
                                        </div>
                                    ))}
                                </div> :

                                <div>                                    
                                   
                                    {
                                        quoteOption?.deviationItinerary !== null ?
                                            quoteOption?.deviationProducts.map(product => 
                                            <>

                                                <div className="row  margin-top-1x">
                                                    <div className="hotel-pic">
                                                        <img src={product?.url} alt="text" />
                                                    </div>
                                                    <div className="hotel-details">

                                                        <div className="pull-left">
                                                            {
                                                                <Icon
                                                                    name="map"
                                                                    className={"icon-16 margin-right font-icon flight-icon"}
                                                                />
                                                            }    
                                                                                                                        
                                                        </div>
                                                        <div className="pull-left value">
                                                            <strong>                                                               
                                                                {product?.destination?.name !== "" ? product?.destination?.name + " " : ""}
                                                                {product?.destination?.providerCode !== "" ? "(" + product?.destination?.providerCode + ") " : ""}
                                                            </strong>
                                                        </div>

                                                        <div className="pull-left value">
                                                            <Icon
                                                                name={product?.productType?.toLowerCase()}
                                                                className={"icon-16 margin-right font-icon flight-icon"}
                                                            /> {product?.name}
                                                        </div>

                                                        <div className="pull-left value">
                                                            <Icon
                                                                    name="passengers"
                                                                    className="icon-16 font-icon"
                                                                />
                                                                {quoteOption.noOfAdults > 0 && `${quoteOption.noOfAdults} ${this.localize(quoteOption.noOfAdults > 1 ? "qma-adults" : "qma-adult")}`}
                                                                {quoteOption.noOfChildren > 0 && `, ${quoteOption.noOfChildren} ${this.localize(quoteOption.noOfChildren > 1 ? "qma-children" : "qma-child")}`}
                                                        </div>
                                                        <div className="pull-left value">
                                                                <Icon
                                                                    name="accommodations"
                                                                    className="icon-16 font-icon margin-right"
                                                                />
                                                                {product?.roomTypes[0]?.name}                                                                
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </>                                                
                                        )
                                        : null
                                    } 
                                    {
                                        (quoteOption?.deviationItinerary !== null ?
                                            <div className="row margin-top-1x margin-bottom-1x">
                                                <ProductTypeItinerary
                                                handleItinerary={(event, data) =>
                                                    handleItinerary(event, data, isItinerary)
                                                }
                                                quote={quote}
                                                quoteOption={quoteOption}
                                                product={quoteOption}
                                                productType={quoteOption?.productType?.name.toLowerCase()}
                                                forPDF={forPDF}
                                                languageCode={this.state.languageCode}
                                                />
                                            </div>
                                        : null
                                        )                                    
                                    }
                                </div>
                            }
                        </div>
                    ))}
                </div>
            );
        }
    }
}

export default ProductTypeDetails;