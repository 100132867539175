import React, { Component } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Footer, Section } from "@sunwing/components";
import commonUtilities from "utilities/common";
import "./appFooter.css";

const AppFooter = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const auth = authState?.isAuthenticated;
    const language = commonUtilities.getLanguage();

    if (auth) {

        return (
            <Section>
                <div className="application-footer font-size-14">
                    {language === "en" ?
                        <footer class="Section_module_section_Light__464447c8 Section_module_section_PaddingDefault__464447c8 Footer_module_footer__d5e8ed8d"
                                data-section-theme="light">
                            <div class="Section_module_section__container__464447c8">
                                <div class="Section_module_section__content__464447c8">
                                    <div class="Grid_module_grid__da3b8c09 Grid_module_md3Lg6__3b65f3ed">
                                        <div class="Grid_module_grid__item__da3b8c09 Grid_module_grid__item__3b65f3ed">
                                            <div class="Footer_module_footerSection__d5e8ed8d">
                                                <p>
                                                    <strong>VACATION PACKAGES</strong>
                                                </p>
                                                <ul>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/all-inclusive-vacations">All inclusive</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/all-inclusive-vacations/adults-only-resorts">Adults</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/all-inclusive-vacations/family-resorts">Families</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/all-inclusive-vacations/luxury-top-rated-resorts">Luxury </a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/all-inclusive-vacations/solo-travel">Solo</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="Grid_module_grid__item__da3b8c09 Grid_module_grid__item__3b65f3ed">
                                            <div class="Footer_module_footerSection__d5e8ed8d">
                                                <p>
                                                    <strong>ALL INCLUSIVE RESORTS</strong>
                                                </p>
                                                <ul>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/destinations/costa-rica">Costa Rica resorts</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/destinations/cuba">Cuba resorts</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/destinations/dominican-republic">Dominican Republic resorts </a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/destinations/mexico">Mexico resorts</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/destinations/jamaica">Jamaica resorts </a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/destinations/panama">Panama resorts</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="Grid_module_grid__item__da3b8c09 Grid_module_grid__item__3b65f3ed">
                                            <div class="Footer_module_footerSection__d5e8ed8d">
                                                <p>
                                                    <strong>ABOUT WESTJET VACATIONS QUÉBEC</strong>
                                                </p>
                                                <ul>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/contact-us">Contact us</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/about-us">About</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/help/how-to-book">FAQs</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/terms-and-conditions">Terms and conditions </a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/privacy-policy">Privacy policy</a></li>
                                                </ul>
                                            </div>
                                            <div class="Footer_module_footerSection__d5e8ed8d margin-top-2-5x">
                                                <p>
                                                    <strong>TRAVEL INFORMATION</strong>
                                                </p>
                                                <ul>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/travel-requirements">Destination entry requirements</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/travel-insurance">Protect your vacation</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/en/travel-alerts">Travel alerts</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="Grid_module_grid__item__da3b8c09 Grid_module_grid__item__3b65f3ed">
                                            <div class="Footer_module_footerSection__d5e8ed8d">
                                                <p>
                                                    <strong>SUNWING AIRLINES</strong>
                                                </p>
                                                <ul>
                                                    <li><a href="https://www.sunwing.ca/en/sunwing-airlines/airport-and-web-check-in" class="Footer-module--footer__links--a172c" rel="noopener" target="_blank">Airport and web check-in<span class="vwq-icon vwq-icon-external-micro Icons-module--icon--370a4" aria-hidden="true"></span></a></li>
                                                    <li><a href="https://www.sunwing.ca/en/sunwing-airlines/flight-status-alerts" class="Footer-module--footer__links--a172c" rel="noopener" target="_blank">Flight status and alerts<span class="vwq-icon vwq-icon-external-micro Icons-module--icon--370a4" aria-hidden="true"></span></a></li>
                                                    <li><a href="https://www.sunwing.ca/en/sunwing-airlines/baggage-info" class="Footer-module--footer__links--a172c" rel="noopener" target="_blank">Baggage allowance<span class="vwq-icon vwq-icon-external-micro Icons-module--icon--370a4" aria-hidden="true"></span></a></li>
                                                    <li><a href="https://www.sunwing.ca/en/sunwing-airlines/seat-selection" class="Footer-module--footer__links--a172c" rel="noopener" target="_blank">Seat selection<span class="vwq-icon vwq-icon-external-micro Icons-module--icon--370a4" aria-hidden="true"></span></a></li>
                                                    <li><a href="https://www.sunwing.ca/en/sunwing-airlines/special-assistance" class="Footer-module--footer__links--a172c" rel="noopener" target="_blank">Special assistance<span class="vwq-icon vwq-icon-external-micro Icons-module--icon--370a4" aria-hidden="true"></span></a></li>
                                                    <li><a href="https://www.sunwing.ca/en/sunwing-airlines/accessibility-plan-and-feedback-process" class="Footer-module--footer__links--a172c" rel="noopener" target="_blank">Accessibility plan and feedback process<span class="vwq-icon vwq-icon-external-micro Icons-module--icon--370a4" aria-hidden="true"></span></a></li>
                                                    <li><a href="https://www.sunwing.ca/en/sunwing-airlines/inflight-service" class="Footer-module--footer__links--a172c" rel="noopener" target="_blank">In-flight service<span class="vwq-icon vwq-icon-external-micro Icons-module--icon--370a4" aria-hidden="true"></span></a></li>
                                                    <li><a href="https://www.sunwing.ca/en/sunwing-airlines/conditions-of-contract" class="Footer-module--footer__links--a172c" rel="noopener" target="_blank">Conditions of carriage and tariffs<span class="vwq-icon vwq-icon-external-micro Icons-module--icon--370a4" aria-hidden="true"></span></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="Footer_module_footerBottom__d5e8ed8d">
                                            <div class="Footer_module_footerCorps__d5e8ed8d">
                                                <a class="Footer_module_footerCorpsLink__d5e8ed8d">
                                                    <img src="https://assets.sunwingtravelgroup.com/image/upload/f_auto/q_auto/v1711986496/WestJet/DIGITAL/VWQ/LOGOS/vacances-westjet-quebec-logo-white-en.svg"
                                                        alt="Tico Logo"
                                                        class="Footer_module_footerCorpsImage__d5e8ed8d"/>
                                                </a>
                                                <div class="logo-blur">
                                                    WestJet Vacations Québec is part of Sunwing Vacations Group, home to North America’s largest vacation brands and the official vacation division of the WestJet Group.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="Footer_module_footerCopyright__d5e8ed8d">
                                            <p>Copyright © 2024 WestJet Vacations Québec | OPC Québec n° 703613</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </footer> :
                        <footer class="Section_module_section_Light__464447c8 Section_module_section_PaddingDefault__464447c8 Footer_module_footer__d5e8ed8d"
                             data-section-theme="light">
                            <div class="Section_module_section__container__464447c8">
                                <div class="Section_module_section__content__464447c8">
                                    <div class="Grid_module_grid__da3b8c09 Grid_module_md3Lg6__3b65f3ed">
                                        <div class="Grid_module_grid__item__da3b8c09 Grid_module_grid__item__3b65f3ed">
                                            <div class="Footer_module_footerSection__d5e8ed8d">
                                                <p>
                                                    <strong>FORFAITS VACANCES</strong>
                                                </p>
                                                <ul>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/voyage-tout-inclus">Tout compris</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/voyage-tout-inclus/adultes-seulement">Pour adultes</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/voyage-tout-inclus/vacances-en-famille">Pour les familles</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/voyage-tout-inclus/hotel-de-luxe">De luxe</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/voyage-tout-inclus/voyageurs-en-solo">En solo</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="Grid_module_grid__item__da3b8c09 Grid_module_grid__item__3b65f3ed">
                                            <div class="Footer_module_footerSection__d5e8ed8d">
                                                <p>
                                                    <strong>HÔTELS TOUT COMPRIS</strong>
                                                </p>
                                                <ul>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/destinations/costa-rica">Hôtels au Costa Rica</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/destinations/cuba">Hôtels à Cuba</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/destinations/republique-dominicaine">Hôtels en République dominicaine </a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/destinations/mexique">Hôtels au Mexique</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/destinations/jamaique">Hôtels en Jamaïque</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/destinations/panama">Hôtels au Panama</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="Grid_module_grid__item__da3b8c09 Grid_module_grid__item__3b65f3ed">
                                            <div class="Footer_module_footerSection__d5e8ed8d">
                                                <p>
                                                    <strong>À PROPOS DE VACANCES WESTJET QUÉBEC</strong>
                                                </p>
                                                <ul>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/communiquer-avec-nous ">Communiquer avec nous </a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/qui-sommes-nous">À propos</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/aide/comment-reserver">FAQ</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/modalities-et-conditions">Modalités et conditions </a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/politique-confidentialite">Politique de confidentialité </a></li>
                                                </ul>
                                            </div>
                                            <div class="Footer_module_footerSection__d5e8ed8d margin-top-2-5x">
                                                <p>
                                                    <strong>RENSEIGNEMENTS SUR LES VOYAGES</strong>
                                                </p>
                                                <ul>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/exigences-d-entree">Exigences d’entrée à destination </a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/assurance-voyage">Assurez vos vacances</a></li>
                                                    <li><a class="Footer-module--footer__links--a172c" target="_blank" href="https://www.vacanceswestjetquebec.com/fr/avis-aux-voyageurs">Alertes de voyage</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="Grid_module_grid__item__da3b8c09 Grid_module_grid__item__3b65f3ed">
                                            <div class="Footer_module_footerSection__d5e8ed8d">
                                                <p>
                                                    <strong>SUNWING AIRLINES</strong>
                                                </p>
                                                <ul>
                                                    <li><a href="https://www.sunwing.ca/fr/sunwing-airlines/airport-and-web-check-in" class="Footer-module--footer__links--a172c" rel="noopener" target="_blank">Enregistrement à l’aéroport et en ligne<span class="vwq-icon vwq-icon-external-micro Icons-module--icon--370a4" aria-hidden="true"></span></a></li>
                                                    <li><a href="https://www.sunwing.ca/fr/sunwing-airlines/statut-de-vol-et-alertes" class="Footer-module--footer__links--a172c" rel="noopener" target="_blank">Statut de vol et alertes<span class="vwq-icon vwq-icon-external-micro Icons-module--icon--370a4" aria-hidden="true"></span></a></li>
                                                    <li><a href="https://www.sunwing.ca/fr/sunwing-airlines/baggage-info" class="Footer-module--footer__links--a172c" rel="noopener" target="_blank">Franchise de bagages<span class="vwq-icon vwq-icon-external-micro Icons-module--icon--370a4" aria-hidden="true"></span></a></li>
                                                    <li><a href="https://www.sunwing.ca/fr/sunwing-airlines/seat-selection" class="Footer-module--footer__links--a172c" rel="noopener" target="_blank">Sélection de sièges<span class="vwq-icon vwq-icon-external-micro Icons-module--icon--370a4" aria-hidden="true"></span></a></li>
                                                    <li><a href="https://www.sunwing.ca/fr/sunwing-airlines/special-assistance" class="Footer-module--footer__links--a172c" rel="noopener" target="_blank">Assistance spéciale<span class="vwq-icon vwq-icon-external-micro Icons-module--icon--370a4" aria-hidden="true"></span></a></li>
                                                    <li><a href="https://www.sunwing.ca/fr/sunwing-airlines/accessibility-plan-and-feedback-process" class="Footer-module--footer__links--a172c" rel="noopener" target="_blank">Plan sur l'accessibilité et processus de rétrocaction<span class="vwq-icon vwq-icon-external-micro Icons-module--icon--370a4" aria-hidden="true"></span></a></li>
                                                    <li><a href="https://www.sunwing.ca/fr/sunwing-airlines/inflight-service" class="Footer-module--footer__links--a172c" rel="noopener" target="_blank">Service en vol<span class="vwq-icon vwq-icon-external-micro Icons-module--icon--370a4" aria-hidden="true"></span></a></li>
                                                    <li><a href="https://www.sunwing.ca/fr/sunwing-airlines/conditions-of-contract" class="Footer-module--footer__links--a172c" rel="noopener" target="_blank">Modalités de transport et tarifs<span class="vwq-icon vwq-icon-external-micro Icons-module--icon--370a4" aria-hidden="true"></span></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="Footer_module_footerBottom__d5e8ed8d">
                                            <div class="Footer_module_footerCorps__d5e8ed8d">
                                                <a class="Footer_module_footerCorpsLink__d5e8ed8d">
                                                    <img src="https://assets.sunwingtravelgroup.com/image/upload/f_auto/q_auto/v1711986497/WestJet/DIGITAL/VWQ/LOGOS/vacances-westjet-quebec-logo-white-fr.svg"
                                                        alt="Tico Logo"
                                                        class="Footer_module_footerCorpsImage__d5e8ed8d"/>
                                                </a>
                                                <div class="logo-blur">
                                                    Vacances WestJet Québec fait partie du Groupe de Vacances Sunwing, la division officielle de vacances du groupe WestJet qui comprend les plus grandes marques de vacances en Amérique du Nord.  
                                                </div>
                                            </div>
                                        </div>
                                        <div class="Footer_module_footerCopyright__d5e8ed8d">
                                            <p>Droits d‘auteur © 2024 Vacances WestJet Québec | OPC Québec n° 703613</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </footer>


                    }

                </div>
            </Section>
        );

    }
};

export default AppFooter;