import messages_fr from "translations/fr.json";
import messages_en from "translations/en.json";
import commonUtilities from "utilities/common";

const messages = { "fr": messages_fr, "en": messages_en };

export const Localize = (id) => {
    const localize = commonUtilities.getLanguage();
    return LocalizeByLang(id, localize);
};

export const LocalizeByLang = (id, LocalizeByLang) => {
    return messages?.[LocalizeByLang]?.[id];
};

export const GetLanguageCode = () => {
    const localize = commonUtilities.getLanguage();
    return localize;
};