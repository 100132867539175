import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "@mui/material";
import { Section } from "@sunwing/components";
import { Icon } from "__ICON_REFERENCE__";

import { Localize } from "utilities/localize";
import { withRouter } from "utilities/withRouter";
import quote from "services/quote/quote.service";
import View from "components/quote/view/index";
import PageHeader from "components/common/pageHeader/pageHeader";

import "./viewQuote.css";

class ViewQuote extends Component {

    componentDidMount() {
        this.getQuote();
    }

    onClose = () => {
        this.props.navigate("/review");
    };

    getQuote() {
        if (this.props.params) {
            let { quoteNumber } = this.props.params;
            this.props.dispatch(quote.get(quoteNumber));
        }
    }

    componentWillUnmount() {
        this.props.dispatch({ type: "QUOTE_RESET" });
    }

    render() {

        let { quote } = this.props;

        return (
            <div className="row margin-bottom-4x review-group-responsive">

                <div className="row">
                    <Section className="print-hide">
                        <div className="page-main-container margin-bottom-1x">
                            <PageHeader />
                        </div>
                    </Section>
                    <Section className="print-hide">
                        {
                            this.props.params?.type?.toLowerCase() === "review"
                                ? <div className="row">
                                    <Button
                                        onClick={() => this.onClose()}
                                        data-testid="close-button"
                                        variant="text"
                                        className="pull-right"
                                    >
                                        <Icon
                                            name="close--n"
                                            className="cursor-pointer icon-16 margin-right"
                                        />
                                        <strong>
                                            {Localize("qma-close")}
                                        </strong>
                                    </Button>
                                </div>
                                : null
                        }

                    </Section>
                </div>
                <View quote={quote} navigate={this.props.navigate} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    quote: state.quote
});

export default connect(mapStateToProps)(withRouter(ViewQuote));