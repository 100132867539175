import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import * as utility from "utilities/utils";
import commonUtilities from "utilities/common";

export default function getProfile() {

    return async (dispatch) => {

        return await client.get(process.env.REACT_APP_PROFILE).then((response) => {

            const languageCode = response?.contactPreferences?.languageCode.toLowerCase();
            const userLanguage = ((languageCode !== undefined && languageCode.length >=2 && languageCode.substring(0, 2) === "fr") ? "fr" : "en");
            
            commonUtilities.setLanguage(userLanguage);

            return dispatch({
                type: actions.GET_PROFILE_SUCCESS,
                payload: utility.getResponse(response)
            });

        })
            .catch((error) => {

                return dispatch({
                    type: actions.GET_PROFILE_FAIL,
                    payload: utility.getErrorResponse(error)
                });

            });

    };

}

export function setProfile(profile) {

    return async (dispatch) => {
        return dispatch({
            type: actions.GET_PROFILE_SUCCESS,
            payload: utility.getResponse(profile)
        });

    }       

}