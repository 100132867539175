import React, { Component } from "react";

import Moment from "moment";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import { Section } from "@sunwing/components";
import { Icon } from "__ICON_REFERENCE__";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  OutlinedInput
} from "@mui/material";

import clearData from "./clearData";
import { searchModel } from "models/search";
import quote from "services/quote/quote.service";
import { Localize } from "utilities/localize";
import { allQuoteOptionsExpired } from "utilities/utils";
import { withRouter } from "utilities/withRouter";
import { CONFIGURATION } from "utilities/configuration";
// import Pagination from "components/common/pagination/pagination";
import { Pagination } from "@material-ui/lab";

import PageHeader from "components/common/pageHeader/pageHeader";
import QuoteModal from "components/common/quoteModal";
import ProductTypeIcon from "components/common/productTypeIcon/productTypeIcon";
import commonUtilities from "utilities/common";
import ReQuoteModel from "components/common/reQuoteModel/reQuote";
import getReQuoteDetails from "services/requote/reQuote.service";

import "reactjs-popup/dist/index.css";
import "./reviewGroupQuotes.scss";

class ReviewGroupQuotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearch: false,
      params: searchModel,
      isShow: false,
      modalIndex: null,
      quoteCollection: [],
      showReQuoteModal: false,
      reQuoteModalIndex: null,
      isReQuote: false,
      isLoading: false
    };
  }

  componentDidMount() {
    this.resetSearchData();
  }

  handleModal = (param, index) => {
    this.setState({
      isShow: param,
      modalIndex: index,
      isReQuote: false
    });
    if (!param) {
      this.getQuotes();
    }
  };

  getQuotes = () => {
    let { params, quoteCollection, isLoading } = this.state;
    if (params.createdOn === "") {
      params.createdOn = null;
    }
    if (params.departureDate === "") {
      params.departureDate = null;
    }
    isLoading = true;
    let getQuoteError = false;
    this.setState({ isLoading, getQuoteError });

    this.props.dispatch(quote.getAll(params))
      .then((response) => {
        isLoading = false;
        if (response?.payload && response?.payload?.status == "PRECONDITION_FAILED") {
          getQuoteError = true;
          this.setState({ isLoading, getQuoteError });
        }
        else {
          quoteCollection = response?.payload?.data;
          this.setState({ quoteCollection, isLoading, getQuoteError });
        }
      })
      .catch((error) => {
        isLoading = false;
        getQuoteError = true;
        this.setState({ isLoading, getQuoteError });
    });
  };

  resetSearchData = () => {
    let params = clearData.search(this.state);
    this.setState(params);
    this.getQuotes();
  };

  setPageNumber = (pageIndexNumber) => {
    let { params } = this.state;
    params.pageIndex = pageIndexNumber;

    this.setState({ params });
    this.getQuotes();
    window.scrollTo(0, 200);
  };

  onHandleSearch = (event) => {
    let { params } = this.state;
    let value = event?.target?.value;
    let name = event?.target?.name?.trim();

    params[name] = value;
    this.setState({ params });
  };

  showReQuote = (param, index) => {
    let { showReQuoteModal, reQuoteModalIndex } = this.state;
    showReQuoteModal = param;
    reQuoteModalIndex = index;
    this.setState({ showReQuoteModal, reQuoteModalIndex });
    if (param) {
      let { quoteCollection } = this.state;
      let quoteNumber = quoteCollection.quotes[index].quoteNumber;
      this.props.dispatch(getReQuoteDetails(quoteNumber));
    }
  };
  reQuote = (param, index) => {
    if (param) {
      this.setState({
        showReQuoteModal: false,
        isShow: param,
        modalIndex: index,
        isReQuote: param
      });
    } else {
      this.setState({
        showReQuoteModal: param,
        isShow: param,
        modalIndex: -1,
        isReQuote: param
      });
    }
  };
  handleExpandorHideSearchClick = () => {
    let { isSearch } = this.state;

    isSearch = !isSearch;
    this.setState({ isSearch });
  };

  onViewQuote = (quoteId) => {
    this.props.navigate("/review/quote/" + quoteId);
  };

  showOrHideDetails = (data) => {
    let { quoteCollection } = this.state;

    quoteCollection.quotes.map((quote) => {
      if (quote.quoteNumber === data.quoteNumber) {
        quote.isExpanded = !quote.isExpanded;
      }
    });

    this.setState({ quoteCollection });
  };
  getTotalPages = (totalRecords, pageSize) => {
    let count = Math.ceil(totalRecords / pageSize);
    return count;
  };

  redirectMigration = (url) => {
    window.open(url, "_blank");
  };

  render() {
    const { isSearch, params, quoteCollection, isLoading, getQuoteError } = this.state;
    const date = new Date();
    date.setHours(0,0,0,0);
    const TODAY = date.toDateString();

    const {
      quoteNumber,
      groupName,
      createdOn,
      departureDate,
      destination,
      departure,
      pageIndex,
      pageSize
    } = params;
    const totalRecords = quoteCollection?.totalRecords;
    const isIQMUser = commonUtilities.isIQMUser();

    return (
      <div className="row margin-bottom-4x review-group-responsive">
        <div className="row">
          <Section>
            <div className="page-main-container margin-bottom-1x">
              <PageHeader />
            </div>
          </Section>

          <Section>
            <div className="search-container margin-bottom-1x">
              <div className="search-row">
                <div
                  className="search-head cursor-pointer font-blue"
                  data-testid="expand-search"
                  onClick={(event) => this.handleExpandorHideSearchClick(event)}
                >
                  <div className="search-text margin-left-1x">
                    <Icon
                      name="search"
                      className="cursor-pointer icon-16 pull-left margin-top-1-4 margin-right"
                    />
                    {Localize("qma-search-specifc-quote")}
                  </div>
                  <Icon
                    name={isSearch ? "arrow-up" : "arrow-down"}
                    className="icon-12 pull-left margin-left-1x margin-top"
                  />
                </div>
              </div>

              <div className={isSearch ? "show search-contents" : "hide"}>
                <div className="col-1-10 box-sizing margin-bottom">
                  <div className="row">
                    <div className="row">{Localize("qma-quote-number")}</div>

                    <OutlinedInput
                      fullWidth
                      name="quoteNumber"
                      id="outlined-quote-number"
                      value={quoteNumber}
                      onChange={(event) => this.onHandleSearch(event)}
                      aria-describedby="outlined-quote-number-text"
                    />
                  </div>
                </div>

                <div className="col-1-8 box-sizing margin-bottom">
                  <div className="row">
                    <div className="row">{Localize("qma-group-name")}</div>

                    <OutlinedInput
                      fullWidth
                      name="groupName"
                      id="outlined-group-name"
                      value={groupName}
                      onChange={(event) => this.onHandleSearch(event)}
                      aria-describedby="outlined-group-name-text"
                    />
                  </div>
                </div>

                <div className="col-1-8 box-sizing margin-bottom">
                  <div className="row">
                    <div className="row">{Localize("qma-created-on")}</div>

                    <OutlinedInput
                      type="date"
                      fullWidth
                      name="createdOn"
                      id="outlined-date"
                      value={createdOn === null ? "" : createdOn}
                      onChange={(event) => this.onHandleSearch(event)}
                      aria-describedby="outlined-group-name-text"
                    />
                  </div>
                </div>
                <div className="col-1-8 box-sizing margin-bottom">
                  <div className="row">
                    <div className="row">{Localize("qma-departure-date")}</div>

                    <OutlinedInput
                      type="date"
                      fullWidth
                      name="departureDate"
                      id="outlined-date"
                      value={departureDate === null ? "" : departureDate}
                      onChange={(event) => this.onHandleSearch(event)}
                      aria-describedby="outlined-group-name-text"
                    />
                  </div>
                </div>
                <div className="col-1-8 box-sizing margin-bottom">
                  <div className="row">
                    <div className="row">{Localize("qma-departure-city")}</div>

                    <OutlinedInput
                      fullWidth
                      name="departure"
                      id="outlined-departure-name"
                      value={departure}
                      onChange={(event) => this.onHandleSearch(event)}
                      aria-describedby="outlined-group-name-text"
                    />
                  </div>
                </div>

                <div className="col-1-8 box-sizing margin-bottom">
                  <div className="row">
                    <div className="row">{Localize("qma-destination")}</div>

                    <OutlinedInput
                      fullWidth
                      name="destination"
                      id="outlined-destination-name"
                      value={destination}
                      onChange={(event) => this.onHandleSearch(event)}
                      aria-describedby="outlined-group-name-text"
                    />
                  </div>
                </div>

                <div className="search-col-actions box-sizing">
                  <div className="row margin-top-2-5x search-option-btn-container">
                    <Button
                      variant="contained"
                      className="pull-left search-btn"
                      data-testid="set-page"
                      onClick={() =>
                        this.setPageNumber(CONFIGURATION.PAGE_INDEX)
                      }
                    >
                      {Localize("qma-search")}{" "}
                    </Button>
                    <Button
                      variant="text"
                      className="pull-left clear-btn"
                      data-testid="reset-search"
                      onClick={() => this.resetSearchData()}
                    >
                      {Localize("qma-clear")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Section>

          <Section>
            <div className="grid-container responsive">
              <div className="grid-head">
                <div className="quote-number Soumission">
                  {Localize("qma-quote-number")}
                </div>

                <div className="quote-group-name">
                  {Localize("qma-group-name")}
                </div>

                <div className="quote-created-on">
                  {Localize("qma-created")}
                </div>

                <div className="quote-options-container box-sizing responsive-quote-options">
                  <div className="quote-options box-sizing">
                    {Localize("qma-option")}
                  </div>

                  <div className="quote-gateway box-sizing margin-right-1x">
                    {Localize("qma-departure-city")}
                  </div>
                  <div className="quote-flight-icon box-sizing margin-right-1x">
                    &nbsp;
                  </div>

                  <div className="quote-destination box-sizing margin-right-1x">
                    {Localize("qma-destination")}
                  </div>

                  <div className="quote-hotel-or-cruise-container margin-right-1x">
                    {Localize("qma-product-name")}
                  </div>

                  <div className="quote-expiry-date text-center box-sizing">
                    {Localize("qma-expiry-date")}
                  </div>
                </div>

                <div className="quote-action box-sizing">
                  {Localize("qma-view")}
                </div>
              </div>

              {!getQuoteError && totalRecords > 0 ? quoteCollection?.quotes?.map((quote, index) => (
                <div key={index} className="grid-row add-option">
                  <div className="quote-number box-sizing">
                    <QuoteModal
                      data-testid="quote_model"
                      data={quote}
                      isShow={
                        index === this.state.modalIndex
                          ? this.state.isShow
                          : false
                      }
                      isReQuote={this.state.isReQuote}
                      handleModal={this.handleModal}
                      reviewQuoteIndex={index}
                    />
                    <ReQuoteModel
                      data-testid="quote_model"
                      data={quote}
                      isShow={
                        index === this.state.reQuoteModalIndex
                          ? this.state.showReQuoteModal
                          : false
                      }
                      handleReQuoteModal={(param, index) => {
                        this.reQuote(param, index);
                      }}
                      reQuoteIndex={index}
                    />

                    <div
                      className="showOrHide margin-right-1x"
                      data-testid="show-hide"
                      onClick={() => this.showOrHideDetails(quote)}
                    >
                      <Icon
                        name={quote.isExpanded ? "minus" : "plus"}
                        className="font-24 font-blue"
                      />
                    </div>

                    {!quote.isMigratedQuote ? (
                      <div className="pull-left">
                        {quote.quoteNumber}

                        <br />

                        {quote?.statusCode?.toLowerCase() !== "cn" &&
                        (isIQMUser ? true : quote.quoteOptions.length < 5 && !allQuoteOptionsExpired(quote)) &&
                        !quote.isRequote ? (
                          <Button
                            data-testid="add-option"
                            variant="text"
                            className="pull-left grid-add-option add-option-link"
                            onClick={() => this.handleModal(true, index)}
                          >
                            {"+" + Localize("qma-add-option")}
                          </Button>
                        ) : (
                          ""
                        )}

                        {quote?.statusCode?.toLowerCase() === "cn" ? (
                          <div className="quote-status-confirmed">
                            {quote.status}
                          </div>
                        ) : quote.isRequote &&
                          !quote?.isManualDeviationOptionPresent ? (
                          <Button
                            data-testid="requote"
                            variant="text"
                            className="pull-left grid-add-option add-option-link"
                            onClick={() => this.showReQuote(true, index)}
                          >
                            {Localize("qma-requote")}
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div className="pull-left">
                        {quote.quoteNumber}

                        <br />
                      </div>
                    )}
                  </div>

                  <div className="quote-group-name box-sizing text-ellipsis">
                    {quote.groupInformation?.groupName}
                  </div>

                  <div className="quote-created-on box-sizing">
                    {Moment(quote.createdDate).format(
                      CONFIGURATION.DATE_FORMAT
                    )}
                  </div>

                  <div className="quote-options-container box-sizing responsive-quote-options">
                    {quote.quoteOptions?.map(
                      (quoteOption, quoteOptionIndex) => (
                        <div
                          key={quoteOptionIndex}
                          className="row margin-bottom-1x"
                        >
                          <div className="quote-options box-sizing">
                            <div className="pull-left font-orange">
                              <ProductTypeIcon
                                productType={
                                  quoteOption.productType?.code.toLowerCase() ===
                                  "deviation"
                                    ? "package"
                                    : quoteOption.productType?.code.toLowerCase()
                                }
                              />
                            </div>

                            <div className="product-type">
                              {quoteOption.productType?.name}
                            </div>
                          </div>

                          <div className="quote-gateway box-sizing text-ellipsis margin-right-1x">
                            {quoteOption.departure?.code?.length > 0 ? (
                              "(" +
                              quoteOption.departure?.code +
                              ") " +
                              quoteOption.departure?.name
                            ) : (
                              <div>&nbsp;</div>
                            )}
                          </div>

                          <div className="quote-flight-icon box-sizing margin-right-1x">
                            {quoteOption?.productType?.code?.toLowerCase() !==
                              "hotel" &&
                            quoteOption.destination?.providerCode?.length >
                              0 ? (
                              <Icon
                                name="flight"
                                className="rotate-90 margin-right font-light-grey"
                              />
                            ) : (
                              <div>&nbsp;</div>
                            )}
                          </div>

                          <div className="quote-destination box-sizing text-ellipsis margin-right-1x">
                            <div className="row">
                              <div className="quote-hotel-or-cruise box-sizing text-ellipsis pull-left">
                                {quoteOption?.productType?.code?.toLowerCase() !== "cruise" && quoteOption.destination?.providerCode?.length > 0 
                                  ?                                 
                                    "(" + quoteOption.destination.providerCode + ") " + quoteOption.destination?.name
                                  : 
                                    quoteOption.destination?.name
                                }
                              </div>
                              <div className="more-container">
                                <Popup on="hover" trigger={quoteOption.destination?.name.length > 2 ? (<div className="more-button">{" +"}</div>) : null} position="bottom center">
                                  <div className="hotel-pop-over-row box-sizing">
                                    {quoteOption?.productType?.code?.toLowerCase() !== "cruise" && quoteOption.destination?.providerCode?.length > 0 
                                      ?                                 
                                    "(" + quoteOption.destination.providerCode + ") " + quoteOption.destination?.name
                                      : 
                                    quoteOption.destination?.name}
                                  </div>
                                </Popup>
                              </div>
                            </div>
                          </div>

                          <div className="quote-hotel-or-cruise-container box-sizing margin-right-1x">
                            {quoteOption.products?.length > 0 &&
                            quoteOption.products?.length > 1 ? (
                              <div className="row">
                                <div className="quote-hotel-or-cruise box-sizing text-ellipsis margin-right">
                                  {quoteOption.products[0]?.name}
                                </div>

                                <div className="more-container">
                                  <Popup
                                    on="hover"
                                    trigger={
                                      <div className="more-button">
                                        {" +"}
                                        {quoteOption.products?.length - 1}
                                      </div>
                                    }
                                    position="bottom center"
                                  >
                                    {quoteOption.products?.map(
                                      (product, productIndex) => (
                                        <div
                                          key={productIndex}
                                          className="hotel-pop-over-row box-sizing"
                                        >
                                          {productIndex +
                                            1 +
                                            ". " +
                                            product.name}
                                        </div>
                                      )
                                    )}
                                  </Popup>
                                </div>
                              </div>
                            ) : quoteOption.products?.length > 0 ? (
                              <div>
                                <div className="quote-hotel-or-cruise box-sizing text-ellipsis">
                                  {quoteOption.products[0].name} &nbsp;
                                </div>
                                <div className="more-container">
                                  <Popup
                                    on="hover"
                                    trigger={
                                      quoteOption.products[0].name?.length >
                                      19 ? (
                                        <div className="more-button">
                                          {" +"}
                                        </div>
                                      ) : null
                                    }
                                    position="bottom center"
                                  >
                                    <div className="hotel-pop-over-row box-sizing">
                                      {quoteOption.products[0].name} &nbsp;
                                    </div>
                                  </Popup>
                                </div>
                              </div>
                            ) : (
                              <div className="quote-hotel-or-cruise box-sizing text-ellipsis">
                                &nbsp;
                              </div>
                            )}
                          </div>

                          <div className="quote-expiry-date text-center box-sizing">
                            {quoteOption?.optionExpiryDate !== "" && quoteOption?.optionExpiryDate !== null &&
                            commonUtilities.calculateDuration(
                              TODAY,
                              quoteOption?.optionExpiryDate
                            ) >= 0 &&
                            commonUtilities.calculateDuration(
                              TODAY,
                              quoteOption?.optionExpiryDate
                            ) < 4 ? (
                              <div className="row text-center">
                                <div className="pull-left font-error margin-right">
                                  {Moment(quoteOption?.optionExpiryDate).format(
                                    CONFIGURATION.DATE_FORMAT
                                  )}
                                </div>

                                <div className="information-icon">
                                  <Popup
                                    on="hover"
                                    trigger={
                                      <div className="product-type-icon">
                                        <Icon
                                          name="alert--o"
                                          className="cursor-pointer icon-16 font-error pull-left"
                                        />
                                      </div>
                                    }
                                    position="bottom center"
                                  >
                                    <div className="error-pop-over-row box-sizing">
                                      {quoteOption?.itineraryExpiryMessage}
                                    </div>
                                  </Popup>
                                </div>
                              </div>
                            ) : commonUtilities.calculateDuration(
                              TODAY,
                              quoteOption?.optionExpiryDate
                            ) < 0 ? (
                              quote?.statusCode?.toLowerCase() === "cn" ? (
                                <div>&nbsp;</div>
                              ) : (
                                <div className="validity-row">
                                  {Localize("qma-expired")}
                                </div>
                              )
                            ) : (quoteOption?.optionExpiryDate === "" || quoteOption?.optionExpiryDate === null ) ? (
                              <div className="validity-row">
                                  {Localize("qma-expired")}
                                </div>
                            ) : (
                              <div className="pull-left margin-right">
                                {Moment(quoteOption?.optionExpiryDate).format(
                                  CONFIGURATION.DATE_FORMAT
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>

                  {!quote.isMigratedQuote ? (
                    <div
                      className="quote-action box-sizing"
                      data-testid="view-quote"
                      onClick={() => this.onViewQuote(quote.quoteNumber)}
                    >
                      <Icon
                        name="my-bookings"
                        className="cursor-pointer icon-24 font-orange"
                      />
                    </div>
                  ) : (
                    <div
                      className="quote-action box-sizing"
                      data-testid="view-quote"
                      onClick={() => this.redirectMigration(quote.migrationUrl)}
                    >
                      <Icon
                        name="my-bookings"
                        className="cursor-pointer icon-24 font-orange"
                      />
                    </div>
                  )}

                  <div className="row screen-quote-options">
                    <div
                      className={
                        quote.isExpanded
                          ? "show quote-options-container font-bold box-sizing"
                          : "hide"
                      }
                    >
                      <div className="quote-options box-sizing">
                        {Localize("qma-option")}
                      </div>

                      <div className="quote-gateway box-sizing margin-right-1x">
                        {Localize("qma-departure-city")}
                      </div>
                      <div className="quote-flight-icon box-sizing margin-right-1x">
                        &nbsp;
                      </div>

                      <div className="quote-destination box-sizing margin-right-1x">
                        {Localize("qma-destination")}
                      </div>

                      <div className="quote-hotel-or-cruise-container margin-right-1x">
                        {Localize("qma-product-name")}
                      </div>

                      <div className="quote-expiry-date text-center box-sizing">
                        {Localize("qma-expiry-date")}
                      </div>
                    </div>

                    <div
                      className={
                        quote.isExpanded
                          ? "show quote-options-container box-sizing"
                          : "hide"
                      }
                    >
                      {quote.quoteOptions?.map(
                        (quoteOption, quoteOptionIndex) => (
                          <div
                            key={quoteOptionIndex}
                            className="row margin-bottom-1x"
                          >
                            <div className="quote-options box-sizing">
                              <div className="pull-left font-orange">
                                <ProductTypeIcon
                                  productType={quoteOption.productType?.code.toLowerCase()}
                                />
                              </div>

                              <div className="product-type">
                                {quoteOption.productType?.name}
                              </div>
                            </div>

                            <div className="quote-gateway box-sizing text-ellipsis margin-right-1x">
                              {quoteOption.departure?.code?.length > 0 ? (
                                "(" +
                                quoteOption.departure?.code +
                                ") " +
                                quoteOption.departure?.name
                              ) : (
                                <div>&nbsp;</div>
                              )}
                            </div>

                            <div className="quote-flight-icon box-sizing margin-right-1x">
                              {quoteOption?.productType?.code?.toLowerCase() !==
                                "hotel" &&
                              quoteOption.destination?.providerCode?.length >
                                0 ? (
                                <Icon
                                  name="flight"
                                  className="rotate-90 margin-right font-light-grey"
                                />
                              ) : (
                                <div>&nbsp;</div>
                              )}
                            </div>

                            <div className="quote-destination box-sizing text-ellipsis margin-right-1x">
                              {quoteOption.destination?.providerCode?.length >
                              0 ? (
                                <div className="pull-left margin-right">
                                  {"(" +
                                    quoteOption.destination.providerCode +
                                    ")"}
                                </div>
                              ) : (
                                <div>&nbsp;</div>
                              )}

                              {quoteOption.destination?.name}
                            </div>

                            <div className="quote-hotel-or-cruise-container box-sizing margin-right-1x">
                              {quoteOption.products?.length > 0 &&
                              quoteOption.products?.length > 1 ? (
                                <div className="row">
                                  <div className="quote-hotel-or-cruise box-sizing text-ellipsis margin-right">
                                    {quoteOption.products[0]?.name}
                                  </div>

                                  <div className="more-container ">
                                    <Popup
                                      on="hover"
                                      trigger={
                                        <div className="more-button">
                                          {" +"}
                                          {quoteOption.products?.length - 1}
                                        </div>
                                      }
                                      position="bottom center"
                                    >
                                      {quoteOption.products?.map(
                                        (product, productIndex) => (
                                          <div
                                            key={productIndex}
                                            className="hotel-pop-over-row box-sizing"
                                          >
                                            {productIndex +
                                              1 +
                                              ". " +
                                              product.name}
                                          </div>
                                        )
                                      )}
                                    </Popup>
                                  </div>
                                </div>
                              ) : quoteOption.products?.length > 0 ? (
                                <div>
                                  <div className="quote-hotel-or-cruise box-sizing text-ellipsis">
                                    {quoteOption.products[0].name} &nbsp;
                                  </div>
                                  <div className="more-container ">
                                    <Popup
                                      on="hover"
                                      trigger={
                                        quoteOption.products[0].name?.length >
                                        19 ? (
                                          <div className="more-button">
                                            {" +"}
                                          </div>
                                        ) : null
                                      }
                                      position="bottom center"
                                    >
                                      <div className="hotel-pop-over-row box-sizing">
                                        {quoteOption.products[0].name} &nbsp;
                                      </div>
                                    </Popup>
                                  </div>
                                </div>
                              ) : (
                                <div className="quote-hotel-or-cruise box-sizing text-ellipsis">
                                  &nbsp;
                                </div>
                              )}
                            </div>

                            <div className="quote-expiry-date box-sizing">
                              {commonUtilities.calculateDuration(
                              TODAY,
                              quoteOption?.optionExpiryDate
                            ) >= 0 &&
                            commonUtilities.calculateDuration(
                              TODAY,
                              quoteOption?.optionExpiryDate
                            ) < 4 ? (
                                <div className="row text-center">
                                  <div className="pull-left font-error margin-right">
                                    {Moment(
                                      quoteOption?.optionExpiryDate
                                    ).format(CONFIGURATION.DATE_FORMAT)}
                                  </div>

                                  <div className="information-icon">
                                    <Popup
                                      on="hover"
                                      trigger={
                                        <div className="product-type-icon">
                                          <Icon
                                            name="alert--o"
                                            className="cursor-pointer icon-16 font-error pull-left"
                                          />
                                        </div>
                                      }
                                      position="bottom center"
                                    >
                                      <div className="error-pop-over-row box-sizing">
                                        {quoteOption?.itineraryExpiryMessage}
                                      </div>
                                    </Popup>
                                  </div>
                                </div>
                              ) : commonUtilities.calculateDuration(
                                TODAY,
                                quoteOption?.optionExpiryDate
                              ) === 0 ? (
                                quote?.statusCode?.toLowerCase() === "cn" ? (
                                  <div>&nbsp;</div>
                                ) : (
                                  <div className="validity-row">
                                    {Localize("qma-expired")}
                                  </div>
                                )
                              ) : commonUtilities.calculateDuration(
                                TODAY,
                                quoteOption?.optionExpiryDate
                              ) === -1 ? (
                                ""
                              ) : (
                                <div className="pull-left margin-right">
                                  {Moment(quoteOption?.optionExpiryDate).format(
                                    CONFIGURATION.DATE_FORMAT
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )) : <div className="page-main-container font-error">{Localize("qma-no-quote-found")}</div>}
            </div>

            {totalRecords > 0 && (
              <div className="pagination_box">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Pagination
                    count={this.getTotalPages(totalRecords, pageSize)}
                    size="large"
                    page={pageIndex}
                    variant="outlined"
                    shape="rounded"
                    onChange={(event, pageNumber) =>
                      this.setPageNumber(pageNumber)
                    }
                    showLastButton
                    showFirstButton
                  />
                </Box>
              </div>
            )}
          </Section>
        </div>
        {isLoading ? (
          <div className="lds-container">
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  quotes: state.quotes
});

export default connect(mapStateToProps)(withRouter(ReviewGroupQuotes));
