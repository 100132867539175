import React, { Component, Fragment } from "react";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { withRouter } from "utilities/withRouter";
import { Section } from "@sunwing/components";
import { Button, Checkbox, FormControlLabel, FormHelperText, OutlinedInput, Radio, RadioGroup } from "@mui/material";
import { Localize } from "utilities/localize";
import commonUtilities from "utilities/common";
import { CONSTANTS } from "utilities/constants";
import getSfdc from "services/sfdc/sfdc.service";
import bookingInVoice from "services/invoice/invoice.service";
import ViewInvoice from "modules/viewInvoice/viewInvoice";
import "./inVoice.css";
import PageHeader from "components/common/pageHeader/pageHeader";
import CancelPopup from "../cancelPopup";
import InvoiceError from "../popups/invoiceError";
import * as actions from "modules/common/store/actions";

class inVoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkbox: "",
            checkboxValid: false,
            selectedCheckBox: 0,
            errorMsg: "",
            isInvoice: false,
            isBooking: false,
            isPayment: false,
            isShowDelete: false,
            ttsBookingNumber: "",
            masterBookingNumber: "",
            loadingInvoice: true,
            showInvoiceError: false,
            apiErrorMessage: "",
            customerLanguage: commonUtilities.getLanguage()
        };
    }

    componentDidMount() {
        this.getLead();
    }

    getLead() {
        if (this.props.currentRoute && this.props.currentRoute?.includes("lead")) {
            let { currentRoute } = this.props.currentRoute;
            currentRoute = this.props.currentRoute?.split("/");
            let leadNumber = currentRoute[2];
            // console.log("Lead", leadNumber);
            if (leadNumber && leadNumber?.length > 0) {
                this.props.dispatch(getSfdc(leadNumber)).then((response) => {
                    let { ttsBookingNumber, loadingInvoice, masterBookingNumber } = this.state;
                    loadingInvoice = false;  
                    ttsBookingNumber = response?.payload?.data?.data?.ttsBookingNumber;

                    masterBookingNumber = response?.payload?.data?.data?.masterBookingNumber;

                    this.setState({ttsBookingNumber, loadingInvoice, masterBookingNumber});
                    

                });
            }
        }
    }
    handleCheckbox = () => {
        let { isInvoice, isBooking, isPayment, loadingInvoice } = this.state;
        if (!isInvoice && !isBooking && !isPayment) {
            this.setState({
                errorMsg: Localize("qma-invoice-error-msg")
            });
        }
        else if (isInvoice || isBooking || isPayment) {
            loadingInvoice = true;
            this.setState({ loadingInvoice });
            const { sfdc } = this.props;
            const data = sfdc?.data?.data;
            let updatedBookingResponse = sfdc;
            const { language } = data;
            const { leadNumber } = this.props.params;
            const { isInvoice, isBooking, isPayment, ttsBookingNumber, masterBookingNumber } = this.state;
            if (updatedBookingResponse?.data?.data){
                updatedBookingResponse.data.data.ttsBookingNumber = ttsBookingNumber;
                updatedBookingResponse.data.data.masterBookingNumber = masterBookingNumber;
            }
            this.props.dispatch({
                type: "GET_SFDC_SUCCESS",
                payload: updatedBookingResponse
            });


            let payload =
            {
                isInvoice: isInvoice,
                isBookingDetail: isBooking,
                isPayment: isPayment,
                bookings: ttsBookingNumber,
                masterBookings: masterBookingNumber,
                language: language, // English (EN) or French (FR)
                email: null // This will be null for this endpoint.
            };
            
            this.props
                .dispatch(bookingInVoice.getInvoice(payload))
                .then((response) => {
                    loadingInvoice = false;
                    this.setState({ loadingInvoice });

                    if (response.type === actions.GET_INVOICE_FAIL) {
                        this.setState({
                            apiErrorMessage: Localize("qma-invoice-dispatch-fail")
                        });
                        this.setShowInvoiceError(true);
                    }
                    else {
                     if (response.payload?.data?.statusCode === 200) {
                        this.props.navigate(`/lead/${leadNumber}/ViewInvoice`);
                     }
                     else {
                        this.setState({
                            apiErrorMessage: response.payload?.data?.message
                        });
                        this.setShowInvoiceError(true);
                     }
                    }
                });
        }



    };

    setInvoice = (value) => {
        this.setState({
            isShowDelete: value
        });
    };

    setShowInvoiceError = (value) => {
        this.setState({
            showInvoiceError: value
        });
    };

    cancelInvoice() {
        window.open("about:blank", "_self");
        window.close();

    }

    updateBookingNumbers(value) {
        let { ttsBookingNumber } = this.state;
        ttsBookingNumber = value;
        this.setState({ ttsBookingNumber });

    }

    updateMasterBookingNumbers(value) {
        let { masterBookingNumber } = this.state;
        masterBookingNumber = value;
        this.setState({masterBookingNumber});

    }

    render() {
        const { sfdc, contentfulMasterData } = this.props;
        const data = sfdc?.data?.data;
        const { isInvoice, isBooking, isPayment, errorMsg, isShowDelete, ttsBookingNumber, loadingInvoice, customerLanguage, showInvoiceError, apiErrorMessage, masterBookingNumber  } = this.state;
        if (!data) return null;
        const { language  } = data;
        
        const contentfulData = contentfulMasterData?.data?.items;
        const pageInformation = commonUtilities?.getContentfulDataById(
            contentfulData,
            "qma-my-group-vacation-page-information"
        );

        return (
            <Fragment>
                <InvoiceError
                    isShow={showInvoiceError}
                    apiErrorMessage={apiErrorMessage}
                    handleClose={() => this.setShowInvoiceError(false)}
                />

                <CancelPopup
                    isShow={isShowDelete}
                    handleInvoie={() => this.cancelInvoice()}
                    handleCancel={() => this.setInvoice(false)}
                    data="Are you sure you want to Cancel?"
                />
                <div className="row margin-bottom-4x ">
                    <div className="row">
                        <Section>
                            <div className="page-main-container margin-bottom-1x">
                                <div className="page-header-container">
                                    <div className="section-header">
                                        <h1>
                                        GROUP INVOICE
                                        </h1>
                                    </div>
                                    <div className="row margin-bottom-1x">
                                        {ReactHtmlParser(pageInformation)}
                                    </div>
                                </div>
                            </div>
                        </Section>
                        <Section>
                            <div className="row border" data-testid="invoice-container" >
                                <div className="wizard-container">
                                    <div className="row margin-bottom-1x">
                                        <div className="row font-bold required" >
                                            {Localize("qma-group-invoice")}
                                        </div>
                                        <div className="row">
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.isInvoice} />}
                                                label={customerLanguage === "en" ? "Invoice" : "Facture"}
                                                onChange={() => this.setState(prev => { return { isInvoice: !prev.isInvoice }; })}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.isBooking} />}
                                                label={customerLanguage === "en" ? "Booking details" : "Les détails de réservation"}
                                                onChange={() => this.setState(prev => { return { isBooking: !prev.isBooking }; })}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.isPayment} />}
                                                label={customerLanguage === "en" ? "Payment summary" : "Résumé de paiement"} 
                                                onChange={() => this.setState(prev => { return { isPayment: !prev.isPayment }; })}
                                            />
                                        </div>

                                        <FormHelperText className="error-msg">
                                            {!isInvoice && !isBooking && !isPayment && errorMsg}
                                        </FormHelperText>
                                    </div>
                                    <div className="row margin-bottom-1x">
                                        <div className="row required ">
                                            {Localize("qma-booking-numbers")}
                                        </div>
                                        <OutlinedInput
                                            fullWidth
                                            inputProps=""
                                            name=""
                                            id=""
                                            value={ttsBookingNumber}
                                            aria-describedby=""
                                            disabled={false}
                                            onChange={(e) => this.updateBookingNumbers(e?.target?.value)}
                                        />
                                        <div className="row">
                                            {Localize("qma-one-or-more-numbers")}
                                        </div>
                                    </div>
                                    <div className="row margin-bottom-1x">
                                        <div className="row">
                                            {Localize("qma-master-booking-numbers")}
                                        </div>
                                        <OutlinedInput
                                            fullWidth
                                            inputProps=""
                                            name=""
                                            id=""
                                            value={masterBookingNumber}
                                            aria-describedby=""
                                            disabled={false}
                                            onChange={(e) => this.updateMasterBookingNumbers(e?.target?.value)}
                                        />
                                        <div className="row">
                                            {Localize("qma-one-or-more-numbers")}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="row font-bold margin-bottom required">
                                            {Localize("qma-invoice-language")}
                                        </div>
                                        <div className="row margin-bottom-2x">
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="code"
                                                onChange={(e) => console.log(e.target.value)}
                                                defaultValue={language}
                                                value={language}
                                                disabled={true}
                                            >
                                                <FormControlLabel value="English" control={<Radio />} label="English" />
                                                <FormControlLabel value="French" control={<Radio />} label="French" />
                                            </RadioGroup>
                                        </div>
                                    </div>
                                    <div className="row margin-bottom">
                                        <Button
                                            // disabled={!this.state.formValid}
                                            data-testid={"generate-invoice-btn"}
                                            onClick={this.handleCheckbox}
                                            className="margin-right-1x"
                                            variant="contained">
                                            {Localize("qma-generate-invoice")}
                                        </Button>
                                        <Button
                                            data-testid={"cancel-btn"}
                                            onClick={() => this.setInvoice(true)}
                                            variant="outlined">
                                            {Localize("qma-cancel")}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Section>
                    </div>
                </div>


                {loadingInvoice  ?  <div className="lds-container">
                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div> : null}


            </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    sfdc: state.sfdc,
    inVoice: state.inVoice,
    contentfulMasterData: state.contentfulMasterData
});
export default connect(mapStateToProps)(withRouter(inVoice));