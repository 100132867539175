import React from "react";
import Button from "@mui/material/Button";
import { Icon } from "__ICON_REFERENCE__";
import { withRouter } from "utilities/withRouter";
import styles from "./getQuoteError.module.scss";
import { Localize } from "utilities/localize";

function GetQuoteError(props) {

    const { isShow, onCancel } = props;

    return (
        <div className={styles.modal}>
            <div className={isShow ? "show" : "hide"}>

                <div className="popup-container-bg"></div>

                <div className="popup-container">

                    <div className="popup-container-border">

                        <span data-testid="close-button" onClick={() => onCancel()}>
                            <Icon name="close--n" className="icon" />
                        </span>

                        <div className="popup-heading margin-bottom-1x">
                            {Localize("qma-get-quote-error-title")}
                        </div>

                        <div className="popup margin-bottom-1x">
                            {Localize(process.env.REACT_APP_BRAND + "-qma-contact")}
                        </div>

                        <div className="row">

                            <Button data-testid={"no-button"} className="BackbuttonCss" onClick={() => onCancel()} variant="contained">
                                {Localize("qma-back-to-quote-page")}
                            </Button>

                        </div>

                    </div>

                </div>

            </div>
        </div>

    );
}

export default withRouter(GetQuoteError);