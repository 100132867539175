import React, { Component, Fragment } from "react";
import { Button, OutlinedInput } from "@mui/material";
import { Icon } from "__ICON_REFERENCE__";

import DeleteModal from "../deleteModal";
import ProductType from "../productType/productType";
import DeviationType from "../productType/deviationType";

import { Localize } from "utilities/localize";
import { CONSTANTS } from "utilities/constants";
import commonUtilities from "utilities/common";
import { connect } from "react-redux";

import "./productTypes.scss";

class ProductTypes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowDelete: false,
            optionIndex: -1,
            selectedQuoteOption: null,
            optionType: ""
        };
    }

    setDeleteModal = (index, quoteOption, optionType) => {
        this.setState({
            isShowDelete: true,
            optionIndex: index,
            selectedQuoteOption: quoteOption,
            optionType
        });
    };

    handleDeleteOption = (value) => {
        const { optionIndex, optionType } = this.state;
        if (value) {
            if (optionType !== "DEVIATION") {
                this.props.handleQuoteOptionDelete(optionIndex);
            } else {
                this.props.handleQuoteDeviationOptionDelete(optionIndex);
            }
        }
        this.setState({
            isShowDelete: false,
            optionType: ""
        });
    };

    renderOptionIndex = (index) => {
        const { data } = this.props;
        const { createOptionSource, currentRoute, quoteData, quotesData, 
            reviewQuoteIndex, isReQuote, isClone, travelQuote } = data;

        let { productTypes } = travelQuote;

        // re-calculate the deviation count in front of the current index.
        // and deduct it when rendering the page
        let deviationCount = 0;

        productTypes?.map((productType, i) => {
            if (productType?.productType?.code?.toLowerCase() === "deviation" && i < index) {
                deviationCount++;
            }
        });

        if (createOptionSource && createOptionSource === CONSTANTS.MODAL && (!isReQuote && !isClone)) {
            return currentRoute.split("/").includes(CONSTANTS.QUOTE)
                ? index + quoteData?.data?.quoteOptions?.length + 1 - deviationCount
                : index + quotesData?.data?.quotes[reviewQuoteIndex]?.quoteOptions?.length + 1 - deviationCount;
        } else if (createOptionSource && createOptionSource === CONSTANTS.MODAL && (isReQuote || isClone)) {
            return currentRoute.split("/").includes(CONSTANTS.QUOTE)
                ? index + quoteData?.data?.quoteOptions?.length - deviationCount
                : index + 1 - deviationCount;
        }

        return index + 1 - deviationCount;
    };

    renderOptionIndexDeviation = (index) => {
        const { data } = this.props;
        const { createOptionSource, currentRoute, quoteData, quotesData, 
            reviewQuoteIndex, isReQuote, isClone, travelQuote } = data;
        let { productTypes } = travelQuote;
        
        let nonDeviationCount = 0;

        productTypes?.map((productType, i) => {
            if (productType?.productType?.code?.toLowerCase() !== "deviation") {
                nonDeviationCount++;
            }
        });
        if (createOptionSource && createOptionSource === CONSTANTS.MODAL && (!isReQuote && !isClone)) {
            return currentRoute.split("/").includes(CONSTANTS.QUOTE) ?
                (index + quoteData?.data?.quoteOptions?.length) + 1 + nonDeviationCount:
                index + quotesData?.data?.quotes[reviewQuoteIndex]?.quoteOptions?.length + 1 + nonDeviationCount;
        } else if (createOptionSource && createOptionSource === CONSTANTS.MODAL && (isReQuote || isClone)) {
            return currentRoute.split("/").includes(CONSTANTS.QUOTE) ?
                index + quoteData?.data?.quoteOptions?.length + 1 + nonDeviationCount:
                index + nonDeviationCount;
        }
        return index + 1 + nonDeviationCount;
    };
    setDeviationEdit(index) {
        const { data, setDeviationEditOption } = this.props;
        const { travelQuote } = data;

        let { productTypes } = travelQuote;
        let deviationIndexes = [];
        productTypes?.map((productType, i) => {
            if (productType?.productType?.code?.toLowerCase() === "deviation"){
                deviationIndexes.push(i);
            }
        });
        setDeviationEditOption(deviationIndexes[index], index);
    }

    render() {
        const { data, events, submitGetQuote, setEditOption, setAddOption } = this.props;
        const { isShowDelete, optionIndex, optionType } = this.state;
        const { travelQuote, reviewQuoteIndex, isReQuote, isClone, cloneIndex, isCloneEdited } = data;
        let { productTypes, deviationTypes } = travelQuote;

        let viewExistingProductCount = data?.quoteData?.data?.quoteOptions.length;
        let reviewExistingProductCount = isReQuote ? data?.quoteData?.data?.quoteOptions.length : data?.quotesData?.data?.quotes[reviewQuoteIndex]?.quoteOptions.length;
        let totalViewProduct = isReQuote ? productTypes?.length : (viewExistingProductCount + productTypes?.length);
        let totalReviewProduct = isReQuote ? productTypes?.length : (reviewExistingProductCount + productTypes?.length);
        const isIQMUser = commonUtilities.isIQMUser();
        // console.log("productTypes ::: ", productTypes, "data", data);
        if (isClone && !isCloneEdited) {
            setEditOption(cloneIndex);
        }
        let { currentRoute } = this.props.data.currentRoute;
        currentRoute = this.props.data.currentRoute?.split("/");
        let leadNumber = currentRoute[2];

        return (
            <Fragment>
                <DeleteModal
                    isShow={isShowDelete}
                    productType={optionType === "DEVIATION" ? deviationTypes ? deviationTypes[optionIndex] : [] : productTypes ? productTypes[optionIndex] : []}
                    optionType={optionType}
                    handleDelete={(value) => this.handleDeleteOption(value)}
                />
                <div className="row product-type-container">
                    {productTypes?.map((productType, index) => productType.productType?.code === "Deviation" ? null : (
                        <div className="row padding-bottom-1x margin-bottom-1x" key={index}>
                            {
                                <>
                                    <div className="row option-header">
                                        {isClone ?
                                            <div className="d-flex">
                                            <span className="option-heading">
                                                    Option {this.renderOptionIndex(index) + 1}
                                                </span> 
                                                <strong>{productType?.productType?.name}</strong>
                                            </div> :
                                            <div className="pull-left ">
                                                <span className="option-heading margin-right">
                                                    Option {this.renderOptionIndex(index)}
                                                </span> 
                                                <strong className="product-type-title">{productType?.productType?.name}</strong>
                                            </div>
                                        }
    {/* 
                                        <div className="pull-left">
                                            <strong>{productType?.productType?.name}</strong>
                                        </div> */}

                                        <div className="pull-right edit-cancel-btn">
                                            <Button
                                                data-testid={"quoteOptions-edit-btn"}
                                                className={productTypes?.length > 1 ? "margin-right-1x" : ""}
                                                onClick={() => setEditOption(index)}
                                                variant="text"
                                            >
                                                <Icon name="edit--n" className="margin-right" />
                                                {Localize("qma-edit")}
                                            </Button>

                                            {productTypes?.length > 1 ? (
                                                <Button
                                                    data-testid={"quoteOptions-delete-btn"}
                                                    className="delete-link"
                                                    variant="text"
                                                    onClick={() => this.setDeleteModal(index, productType, "PRODUCT")}
                                                >
                                                    <Icon name="close--o" className="margin-right" />
                                                    {Localize("qma-delete") + " "}
                                                </Button>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                    <div className={productType.isManualQuote || productType?.productType?.code?.toLowerCase() === "deviation" ? "show manual-quote" : "hide"}>
                                        <div className="row margin-bottom-1x option-icon">
                                            <Icon name="info" className="margin-right" />
                                        </div>
                                        <div className="option-text">
                                            {Localize("qma-manual-quote")}
                                        </div>
                                    </div>

                                    <div className="row"><ProductType productType={productType}></ProductType></div>
                                </>
                            }
                        </div>
                    ))}
                    {deviationTypes?.map((productType, index) => (
                        <div className="row padding-bottom-1x margin-bottom-1x" key={index}>
                            <div className="row">
                                {isClone ?
                                    <div className="pull-left option-heading margin-right">
                                        Option {this.renderOptionIndexDeviation(index) + 1}
                                    </div> :
                                    <div className="pull-left option-heading margin-right">
                                        Option {this.renderOptionIndexDeviation(index)}
                                    </div>
                                }

                                <div className="pull-left">
                                    <strong>{productType?.productType?.name}</strong>
                                </div>

                                <div className="pull-right edit-cancel-btn">
                                    <Button
                                        data-testid={"quoteOptions-edit-btn"}
                                        className={productTypes?.length > 1 ? "margin-right-1x" : ""}
                                        onClick={() => this.setDeviationEdit(index)}
                                        variant="text"
                                    >
                                        <Icon name="edit--n" className="margin-right" />
                                        {Localize("qma-edit")}
                                    </Button>

                                    {productTypes?.length > 1 ? (
                                        <Button
                                            data-testid={"quoteOptions-delete-btn"}
                                            className="delete-link"
                                            variant="text"
                                            onClick={() => this.setDeleteModal(index, productType, "DEVIATION")}
                                        >
                                            <Icon name="close--o" className="margin-right" />
                                            {Localize("qma-delete") + " "}
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>

                            <div className={productType.isManualQuote || productType?.productType?.code?.toLowerCase() === "deviation" ? "show manual-quote" : "hide"}>
                                <div className="row margin-bottom-1x option-icon">
                                    <Icon name="info" className="margin-right" />
                                </div>
                                <div className="option-text">
                                    {Localize("qma-manual-quote")}
                                </div>
                            </div>

                            <div className="row"><DeviationType productType={productType}></DeviationType></div>
                        </div>
                    ))}


                    {(this.props.data?.currentRoute === "/home") || (this.props.data?.currentRoute === "/lead/" + leadNumber + "/quote") ?
                        <div className="row">
                            <div className="row margin-bottom-1-4 font-grey font-14">
                                {Localize("qma-additional-requirement")}
                            </div>
                            <div className="row-additional-information add-info">
                                <OutlinedInput
                                    multiline={true}
                                    fullWidth={true}
                                    rows={4}
                                    inputComponent="textarea"
                                    notched={true}
                                    inputProps={{ maxLength: 250 }}
                                    name="additionalRequirements"
                                    value={travelQuote.additionalRequirements}
                                    onChange={(event) => events.handleStep3Data(event)}
                                />
                            </div>
                        </div> : ""}

                    <div className="row margin-top-1x add-option-btn-container">
                        {isClone ? <div className="pull-right">
                            <Button variant="contained" onClick={submitGetQuote}>
                                {Localize("qma-get-the-quote")}
                            </Button>
                        </div> :
                            <div className="pull-right">
                                {(isIQMUser ? true : totalViewProduct && totalViewProduct < 5) ? (
                                    <Button className="add-option-btn margin-right-1x margin-bottom-1x" data-testid={"quoteOptions-addOption-btn"} variant="outlined" onClick={setAddOption}> {" +" + Localize("qma-add-option") + " "}</Button>
                                ) : (isIQMUser ? true : totalReviewProduct && totalReviewProduct < 5) ? (
                                    <Button className="add-option-btn margin-right-1x margin-bottom-1x" data-testid={"quoteOptions-addOption-btn"} variant="outlined" onClick={setAddOption}> {" +" + Localize("qma-add-option") + " "}</Button>
                                ) : (isIQMUser ? true : !totalReviewProduct && !totalViewProduct && productTypes?.length < 5) ? (
                                    <Button className="add-option-btn margin-right-1x margin-bottom-1x" data-testid={"quoteOptions-addOption-btn"} variant="outlined" onClick={setAddOption}> {" +" + Localize("qma-add-option") + " "}</Button>) : ""}


                                <Button className="add-get-quote-btn margin-bottom-1x" variant="contained" onClick={submitGetQuote}>
                                    {Localize("qma-get-the-quote")}
                                </Button>
                            </div>}

                    </div>
                </div>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({

    reQuoteData: state.reQuoteDetails

});

export default connect(mapStateToProps)(ProductTypes);
