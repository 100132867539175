import React from "react";

import GroupInformation from "./groupInformation/groupInformation";
import ProfileInformation from "../../../../common/profileInformation/profileInformation";

export default function CreateQuoteStepOne(props) {
  const { data, events, errors } = props;
  const { quote, currentStep } = data;

  if (currentStep !== 1) {
    return null;
  }

  return (
    <div className="row">
      <ProfileInformation handleStep1Data={events.handleStep1Data} quote={quote} isHomePage={props.isHomePage}/>
      <GroupInformation
        quote={quote}
        errors={errors}
        handleStep1Data={events.handleStep1Data}
      ></GroupInformation>
    </div>
  );
}
