import React from "react";
import Button from "@mui/material/Button";

import { Localize } from "utilities/localize";
import { withLocalizer } from "utilities/withLocalizer";
import "./index.scss";

function CancelPopup(props) {
    const { isShow, handleInvoie, handleCancel, data } = props;
   

    return (
        <div className={isShow ? "show" : "hide"}>

            <div className="popup-container-bg delete-modal"></div>

            <div className="popup-container">

                <div className="popup-body">

                    <div className="popup-heading margin-bottom-1x">
                        Group Invoice
                    </div>

                    <div className="popup margin-bottom-1x">
                        Are you sure you want to Cancel?
                    </div>

                    <div className="row margin-top-3x yes-no-option-btn">
                        <Button
                            data-testid={"deletemodal-yes-button"}
                            onClick={() => handleInvoie()}
                            className="margin-right-1x yes-btn"
                            variant="contained"
                        >
                            {props.localize("qma-yes")}
                        </Button>
                        <Button data-testid={"deletemodal-no-button"} className="no-btn" onClick={() => handleCancel()} variant="outlined">
                            {props.localize("qma-no")}
                        </Button>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default withLocalizer(CancelPopup);