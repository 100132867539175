const oktaAuthConfig = {
    language: "en",
    i18n: {
        "en": {
            "primaryauth.title": "Sign in",
            "primaryauth.submit": "Sign in"
        }
    },
    features: {
        rememberMe: true,
        selfServiceUnlock: false,
        router: false
    },
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    redirectUri: window.location.origin + "/login/callback",
    authParams: {
        issuer: process.env.REACT_APP_OKTA_ISSUER,
        responseType: ["id_token", "token"],
        scopes: ["openid", "email", "profile", "brand:" + process.env.REACT_APP_BRAND]
    },
    responseMode: "fragment",
    pkce: true,
    scopes: ["openid", "email", "profile", "brand:" + process.env.REACT_APP_BRAND]
};

export { oktaAuthConfig };
