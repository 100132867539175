import { Localize } from "utilities/localize";

export const withLocalizer = (Component) => {
    const Wrapper = (props) => {
        return (
            <Component
                localize={(value) => (<div className="localize-text">{Localize(value)}</div>)}
                {...props}
            />
        );
    };

    return Wrapper;
};