import { withLocalizer } from "./withLocalizer";
import { useLocation } from "wouter";

export const withRouter = (Component) => {
    const Wrapper = (props) => {
        //const setLocation = useLocation();
        const [currentRoute, navigate] = useLocation();

        return (
            <Component
                // navigate={setLocation[1]}
                // currentRoute={setLocation[0]}
                navigate={navigate} // Pass the setLocation function as `navigate`
                currentRoute={currentRoute} // Pass the current route
                {...props}
            />
        );
    };

    return withLocalizer(Wrapper);
};