import React from "react";
import { Icon } from "__ICON_REFERENCE__";
import { Localize } from "utilities/localize";

export default function PreviousButton(props) {
    let { data, events } = props;
    let { currentStep, isEdit, isAdd } = data;

    if (currentStep !== 2) return;

    return (
        <div className="col-3 wizard-button previous-button">
            <div className="pull-left next-link-button visible font-bold"
                onClick={
                    isEdit || isAdd ? events?.cancel : events?.previous}
            >
                <div className="pull-left">
                    <Icon name="arrow-left" className="arrow-l" />
                </div>
                <div className="pull-left font-16">
                    {Localize("qma-navigation-back")}
                </div>
            </div>
        </div >
    );
}