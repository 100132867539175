import React, { useEffect, useRef, useState  } from "react";
import DatePicker , { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import Popup from "reactjs-popup";
import { Icon, Section } from "@sunwing/components";
// import en from "date-fns/locale/en-us";
import moment from "moment";
import { CONFIGURATION } from "utilities/configuration";
import commonUtilities from "utilities/common";
import "../../styles/styles.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./date-range-picker.scss";
import { connect } from "react-redux";
import addDays from "date-fns/addDays";
import addYears from "date-fns/addYears";
import { Box } from "@mui/material";
import { FormHelperText, FormLabel, OutlinedInput } from "@mui/material";
import { Localize } from "utilities/localize";
const DateRangePicker = (props) => {
    const datePickerClass = "date-range-input";
    const minimumDays = 3;
    const minimumDaysHotelOnly = 1;
    const scrollToRef = useRef();
    registerLocale("fr", fr); 
    // registerLocale("en", en); 
    const en = "en";
    const [isDurationHidden, setDurationHidden] = useState(false);
    const [startDate, setStart] = useState(null);
    const [endDate, setEnd] = useState(null);
    const [isInvalidRange, setRangeValue] = useState(false);
    const [includeDates, setEnabledDates] = useState([]);
    const [includeDateIntervals, setEnabledDateIntervals] = useState([]);
    const [rerender, setRerender] = useState(false);
    const [availableDates, setAvailableDates] = useState([]);
    const isIQMUser = commonUtilities.isIQMUser();
    const profileLang = commonUtilities.getLanguage();

    const [includeDepartureDates, setDepartureEnabledDates] = useState([]);
    const [includeReturnDates, setReturnEnabledDates] = useState([]);
    const [returnDateDisabled, setReturnDateDisabled] = useState(true);
    const nightText = Localize("qma-night");

    let productType = commonUtilities.getProductType(props);
    let { departureDate, returnDate } = productType || {};
    let { data, onDateRangeChange, enabledDates, clearDateEvent, isDisabled, errors } = props;
    let deviationTypes = data?.quote?.deviationTypes || {};

    let { minimumDate, maximumDate } = data;
    let minDateEnabled = new Date(minimumDate);
    let maxDateEnabled = new Date(maximumDate);

    useEffect(() => {
        if (departureDate && returnDate) {
            setStart(new Date(departureDate).getTime() + (new Date(departureDate)?.getTimezoneOffset() * 60000));
            setEnd(new Date(returnDate).getTime() + (new Date(returnDate)?.getTimezoneOffset() * 60000));
            props.setDurationHidden(false);
        }
        if (data?.isEdit) {
            if (productType?.productType?.code?.toLowerCase() !== "deviation"){
                if(departureDate && returnDate) {
                    setStart(new Date(departureDate).getTime() + (new Date(departureDate)?.getTimezoneOffset() * 60000));
                    setEnd(new Date(returnDate).getTime() + (new Date(returnDate)?.getTimezoneOffset() * 60000));
                    props.setDurationHidden(false);
                } else {
                    setStart(null);
                    setEnd(null);
                }
            } else {
                let currentIndex = data?.deviationOptionIndex;
                if(deviationTypes[currentIndex]?.departureDate) {
                    setStart(new Date(deviationTypes[currentIndex]?.departureDate).getTime() + (new Date(deviationTypes[currentIndex]?.departureDate)?.getTimezoneOffset() * 60000));
                }
                if(deviationTypes[currentIndex]?.returnDate) {
                    setEnd(new Date(deviationTypes[currentIndex]?.returnDate).getTime() + (new Date(deviationTypes[currentIndex]?.returnDate)?.getTimezoneOffset() * 60000));
                }
                props.setDurationHidden(false);                
            }
        }
        if (props.enabledDates?.data) {
            setRerender(!rerender);
            setAvailableDates(props.enabledDates);
        }
        updateEnabledDates();

    }, [props.enabledDates, availableDates]);


    useEffect(() => {
        if (productType?.isManualQuote){
            enableAllDates();
        }
       
    }, [productType?.isManualQuote]);


    useEffect(() => {

        updateEnabledDates();
        if (productType?.isManualQuote){
            enableAllDates();
        }
    }, [productType?.productType?.code]);

    useEffect(() => {
        if(isDisabled) {
            setReturnDateDisabled(true);
            clear();
        }
    }, [isDisabled]);

    
    useEffect(() => {
    }, [errors]);

    useEffect(() => {
        updateEnabledDates();  
        if (!data?.isEdit) {
            clear();
        }
    }, [props.clearDateEvent]);

    const clear = () => {
        setStart(null);
        setEnd(null);
    };
    const scrollTile = () => {
      
        window.scrollTo(0, 500);
        scrollToRef?.current?.scrollIntoView();        
    };

    const enableAllDates = () => {
        let range =  [{ start: new Date(), end: addDays(new Date(), 1825) }] ;
        setEnabledDateIntervals(range);
    };
    const duration = commonUtilities.calculateDuration(
        productType?.departureDate,
        productType?.returnDate
    );
    const updateEnabledDates = () => {
        if(productType?.isManualQuote){
            return;
        }
        //  we have the data in availableDates: {data: {departureDate:[], returnDate:[]}}
        if (availableDates?.data?.departureDate && availableDates?.data?.departureDate?.length > 0 && productType?.productType?.code.toLowerCase() !== "hotel" || availableDates?.data?.returnDate && availableDates?.data?.returnDate?.length > 0 && productType?.productType?.code.toLowerCase() !== "hotel") {
            // let definedDates = [];
           
            if (availableDates?.data?.departureDate && availableDates?.data?.departureDate?.length > 0 && productType?.productType?.code.toLowerCase() !== "hotel"){
                let definedDates1 = [];
                availableDates?.data?.departureDate?.map((date) => {
                    // definedDates.push(new Date(date)?.getTime();
                    let givenDate = new Date(date);
                    let utcTime = givenDate.getTime() + (givenDate.getTimezoneOffset() * 60000);
                    let minimumDate = addDays(new Date(), 60).getTime();
                    
                    if (utcTime > minimumDate && !isIQMUser) {
                        definedDates1.push(utcTime);
                    }
                    else if (isIQMUser) {
                        definedDates1.push(utcTime);
                    }

                });
                setDepartureEnabledDates(definedDates1);
            }

            // setEnabledDates(definedDates);
            if (availableDates?.data?.returnDate && availableDates?.data?.returnDate?.length > 0 && productType?.productType?.code.toLowerCase() !== "hotel"){
                let definedDates2 = [];
                availableDates?.data?.returnDate?.map((date) => {
                    // definedDates.push(new Date(date)?.getTime();
                    let givenDate = new Date(date);
                    let utcTime = givenDate.getTime() + (givenDate.getTimezoneOffset() * 60000);
                    let minimumDate = addDays(new Date(), 60).getTime();
                    
                    if (utcTime > minimumDate && !isIQMUser) {
                        definedDates2.push(utcTime);
                    }
                    else if (isIQMUser) {
                        definedDates2.push(utcTime);
                    }
                    setReturnEnabledDates(definedDates2);
                });
            }
            
        } else if (productType?.productType?.code?.toLowerCase() === "hotel" ||
                    productType?.productType?.code?.toLowerCase() === "deviation") {
            let range = isIQMUser ? 
                [{ start: new Date(), end: addYears(new Date(), 5) }] : 
                [{ start: addDays(new Date(), 60), end: addDays(new Date(), 365) }];
            setEnabledDateIntervals(range);
            if (!data.isEdit) {
                setStart(null);
                setEnd(null);
                props.setDurationHidden(true);
            }
        } else if (productType?.productType?.code?.toLowerCase() === "cruise") {
            // enableDatesForCruise();
            enableAllDates();
            if (!data.isEdit) {
                setStart(null);
                setEnd(null);
                props.setDurationHidden(true);
            }
        } else {
            if (!data.isEdit) {
                setStart(null);
                setEnd(null);
                props.setDurationHidden(true);
            }
            setEnabledDates([]);
            setEnabledDateIntervals([]);
        }

    };

    const enableDatesForCruise = () => {
        let allowedDays = [0, 2];
        let calculatedDays = [];
        allowedDays.map(allowedDay => {
            calculatedDays = calculatedDays.concat(calculateDays("2023-02-01T00:00:00Z", "2024-04-01T00:00:00Z", allowedDay));
        });
        setEnabledDates(calculatedDays);
    };

    const calculateDays = (startDate, endDate, day) => {
        let start = moment(startDate),
            end = moment(endDate);
        let result = [];
        let current = start.clone();
        while (current.day(7 + day).isBefore(end)) {
            result.push(new Date(current.clone()).getTime());
        }
        return result;

    };

    const setSelectedDates = (dateRange) => {
        let startDate = dateRange[0];
        let endDate = dateRange[1];
        let daysDiff = moment(endDate).diff(moment(startDate), CONFIGURATION.DAYS);
        if (productType?.productType?.code?.toLowerCase() === "hotel") {
            if (daysDiff < minimumDaysHotelOnly) {
                setRangeValue(true);
                return;
            }
        }
        else {
            if (daysDiff < minimumDays) {
                setRangeValue(true);
                return;
            }
        }
        setStart(startDate);
        setEnd(endDate);
        props.setDurationHidden(false);

        handleData(startDate, endDate);

    };
    const handleCalendarClose = () => {
        if (isInvalidRange) {
            let ele = document.getElementsByClassName(datePickerClass);
            if (ele) {
                ele[0]?.click();
                setRangeValue(false);
            }
        }
    };

    const handleData = (startDate, endDate) => {
        let { handleStep2Data, data, handleStep2DeviationDates } = props;
        let { maximumDate } = data;
        let productType = commonUtilities.getProductType(props);
        if (!endDate || endDate === null) {
            props.setDurationHidden(true);
        }
        if (!endDate && moment(startDate).isAfter(moment(maximumDate).subtract(CONFIGURATION.MINIMUM_VACATION_DAYS, "d"))) {
            startDate = moment(maximumDate).subtract(CONFIGURATION.MINIMUM_VACATION_DAYS - 1, "d");
            endDate = moment(data.maximumDate);
        }

        productType.departureDate = moment(startDate).format("YYYY-MM-DD");
        productType.returnDate = endDate && moment(endDate).format("YYYY-MM-DD");
        // productType.duration = endDate ? moment(endDate).diff(moment(startDate), CONFIGURATION.DAYS) : CONFIGURATION.MINIMUM_VACATION_DAYS - 1;
        productType.duration = endDate ? moment(endDate).diff(moment(startDate), CONFIGURATION.DAYS) : "";
        if (startDate && endDate) {
            onDateRangeChange(false);
        }
        if(productType?.productType?.code?.toLowerCase() !== "deviation") {
            handleStep2Data(productType);
            
        } else {
            handleStep2DeviationDates(productType);
        }

    };
    const handleStartDateChange = (date) => {
        setSelectedDates([date, ""]);   
        setReturnDateDisabled(((productType?.productType?.code.toLowerCase() === "package" || productType?.productType?.code.toLowerCase() === "flight" || productType?.productType?.code.toLowerCase() === "cruise") && (productType?.destination?.code === "" || productType?.departure.code === "")) || (productType?.productType?.code.toLowerCase() === "hotel" && productType?.destination?.code === "") || (productType?.productType?.code.toLowerCase() === "deviation" && deviationTypes[0]?.departures.length <= 0));
    };
      
    const handleEndDateChange = (date) => {
        setSelectedDates([startDate, date]);
    };

// console.log(productType, deviationTypes)
// console.log(props);

    return (
        <div className="date-range-picker_" ref={scrollToRef}>
            {(productType?.productType?.code.toLowerCase() === "hotel" ||
                productType?.productType?.code.toLowerCase() === "deviation" ||
                productType?.productType?.code.toLowerCase() === "cruise" || productType?.isManualQuote) ?
                <Box>
                    <div className="row">
                    <div className="col-3">
                        <div className="row">
                        {Localize("qma-departure-date")}
                        </div>
                        <DatePicker className="date-range" width="100%"
                            selected={startDate}
                            onChange={handleStartDateChange}
                            selectsStart
                            startDate={startDate}
                            locale={profileLang === "en" ? null :  fr}
                            endDate={endDate}
                            disabled={isDisabled}
                            dateFormat="dd MMM yyyy"
                        />
                        <FormHelperText error={errors?.departureDate}>
                                {errors?.departureDate?.trim().length > 0 &&
                                    errors?.departureDate}
                        </FormHelperText> 
                    </div>
                    <div className="col-3">
                        <div className="row">
                        {Localize("qma-return-date")}
                        </div>
                        <DatePicker className="date-range" width="100%"
                            selected={endDate}
                            onChange={handleEndDateChange}
                            selectsEnd
                            startDate={startDate}
                            locale={profileLang === "en" ? null :  fr}
                            endDate={endDate}
                            minDate={startDate}
                            disabled={returnDateDisabled}
                            dateFormat="dd MMM yyyy"
                        />
                        <FormHelperText error={errors?.returnDate}>
                                {errors?.returnDate?.trim().length > 0 &&
                                    errors?.returnDate}
                        </FormHelperText> 
                    </div>
                    {!isDurationHidden && duration !== 0?
                        <div className="duration">{duration + " " + nightText}</div> : null}
                    </div>
                </Box>
                : !productType?.isManualQuote ? (
                <Box>
                    <div className="row">
                        <div className="col-3">
                            <div className="row">
                                {Localize("qma-departure-date")}
                            </div>
                            <DatePicker className="date-range" width="100%"
                                selected={startDate}
                                onChange={handleStartDateChange}
                                minDate={minDateEnabled}
                                locale={profileLang === "en" ? null :  fr}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                disabled={isDisabled}
                                dateFormat="dd MMM yyyy"
                                includeDates={includeDepartureDates}
                            />   
                            <FormHelperText error={errors?.departureDate}>
                                {errors?.departureDate?.trim().length > 0 &&
                                    errors?.departureDate}
                            </FormHelperText>                        
                        </div>

                        <div className="col-3">
                            <div className="row">
                                {Localize("qma-return-date")}
                            </div>
                            <DatePicker className="date-range" width="100%"
                                selected={endDate}
                                onChange={handleEndDateChange}
                                minDate={startDate}
                                locale={profileLang === "en" ? null :  fr}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                disabled={returnDateDisabled}
                                dateFormat="dd MMM yyyy"
                                includeDates={includeReturnDates}
                            />
                            <FormHelperText error={errors?.returnDate}>
                                {errors?.returnDate?.trim().length > 0 &&
                                    errors?.returnDate}
                            </FormHelperText>  
                        </div>
                        <div className="col-3">
                            <div className="row">
                                    {isIQMUser ? "" : <div className="information-icon">
                                    <Popup
                                        on="hover"
                                        trigger={
                                            <div className="product-type-icon margin-left">
                                                <Icon
                                                    name="info--o"
                                                    className="cursor-pointer icon-16 pull-left"
                                                />
                                            </div>
                                        }
                                        position="bottom left"
                                    >
                                        <div className="info-pop-over-row box-sizing">
                                            {Localize("qma-dates-info")}

                                        </div>
                                    </Popup>
                                </div>}
                            </div>
                            {!isDurationHidden && duration !== 0?
                            <div className="duration">{duration + " " + nightText}</div> : null}
                        </div>
                    </div>
                </Box>                    
                )
                : null}
        </div>
    );
};

const mapStateToProps = (state) => ({
    enabledDates: state.flightsDates
});

export default connect(mapStateToProps)(DateRangePicker);