import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Icon } from "__ICON_REFERENCE__";

import "./promotions.scss";

const Promotion = (props) => {
  const { promotions, isEdit, handleClose } = props;
  // console.log("props::: ", props);
  const handleRemove = (val, i) => {
    handleClose(val, i);
  };

  return (
    <div>
      {!isEdit ? (
        <div className="row promotion-container" data-testid="promotion-list">
          {promotions?.map((promotion, promotionIndex) => (
            <div key={promotionIndex} className="promotion-tile items">
              <div className="row">
                <div className="promotion-icon" data-testid="promotionicon">
                  <Icon
                    name="promotion"
                    className="icon font-orange margin-right"
                  />
                </div>
                <h3 data-testid="promotion-title">{promotion.title}</h3>
              </div>

              <div className="row margin-top-1x" data-testid="desc">
                {ReactHtmlParser(promotion.description)}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="row promotion-container" data-testid="promotion-list">
          {promotions?.map((promotion, promotionIndex) => (
            <div key={promotionIndex}>
              {promotion?.isRemoved ? (
                <div
                  key={promotionIndex}
                  className="promotion-tile-undo items border-promotion"
                >
                  <div className="row">
                    <div
                      className="promotion-icon-undo"
                      data-testid="promotionicon"
                    >
                      <Icon
                        name="promotion"
                        className="icon font-white margin-right"
                      />
                    </div>

                    <h3 data-testid="promotion-title">{promotion.title}</h3>

                    <div onClick={() => handleRemove(false, promotionIndex)}>
                      <Icon
                        name="refresh--o"
                        className="icon-24 cross-button icon-flipped pull-left margin-left-1x margin-top close-icon"
                      />
                    </div>
                  </div>

                  <div className="row margin-top-1x" data-testid="desc">
                    {ReactHtmlParser(promotion.description)}
                  </div>
                </div>
              ) : (
                <div
                  key={promotionIndex}
                  className="promotion-tile items border-promotion"
                >
                  <div className="row">
                    <div className="promotion-icon" data-testid="promotionicon">
                      <Icon
                        name="promotion"
                        className="icon font-orange margin-right"
                      />
                    </div>

                    <h3 data-testid="promotion-title">{promotion.title}</h3>

                    <div onClick={() => handleRemove(true, promotionIndex)}>
                      <Icon
                        name="close--o"
                        className="icon-24 cross-button pull-left margin-left-1x margin-top close-icon"
                      />
                    </div>
                  </div>

                  <div className="row margin-top-1x" data-testid="desc">
                    {ReactHtmlParser(promotion.description)}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Promotion;
